/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-management.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../doks/components/table/table.component.ngfactory";
import * as i3 from "../../../doks/components/table/table.component";
import * as i4 from "./user-management.component";
import * as i5 from "../../../doks/services/user.service";
import * as i6 from "../user-management.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../doks/services/snack-bar.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
var styles_UserManagementComponent = [i0.styles];
var RenderType_UserManagementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserManagementComponent, data: {} });
export { RenderType_UserManagementComponent as RenderType_UserManagementComponent };
export function View_UserManagementComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { sort: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "dashboard-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "flex direction-column align-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary confirm-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Neuen Benutzer hinzuf\u00FCgen"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "gate-table", [], null, [[null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("edit" === en)) {
        var pd_0 = (_co.editUser($event) !== false);
        ad = (pd_0 && ad);
    } if (("delete" === en)) {
        var pd_1 = (_co.deleteUser($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(6, 114688, null, 0, i3.TableComponent, [], { dataSource: [0, "dataSource"], displayedColumns: [1, "displayedColumns"], userIdToCheck: [2, "userIdToCheck"] }, { edit: "edit", delete: "delete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; var currVal_1 = _co.displayedColumns; var currVal_2 = _co.currentUser.id; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_UserManagementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "service-user-management", [], null, null, null, View_UserManagementComponent_0, RenderType_UserManagementComponent)), i1.ɵdid(1, 245760, null, 0, i4.UserManagementComponent, [i5.UserService, i6.UserManagementService, i7.Router, i8.MatDialog, i9.SnackBarService, i10.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserManagementComponentNgFactory = i1.ɵccf("service-user-management", i4.UserManagementComponent, View_UserManagementComponent_Host_0, {}, {}, []);
export { UserManagementComponentNgFactory as UserManagementComponentNgFactory };
