import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../authentication/guards/admin-guard.service';
import { AuthGuard } from '../authentication/guards/auth-guard.service';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [
  {
    path: 'pages',
    component: Pages,
    children: [
      { path: '', redirectTo: 'stock', pathMatch: 'full' },
      {
        path: 'areas',
        canActivate: [AuthGuard],
        loadChildren: () => import('./siteAdmin/areas.module').then((m) => m.AreasModule),
      },
      {
        path: 'stock',
        canActivate: [AuthGuard],
        loadChildren: () => import('./operator/stock.module').then((m) => m.StockModule),
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'user-management',
        canActivate: [AdminGuard],
        loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'help',
        canActivate: [AuthGuard],
        loadChildren: () => import('./operator/help/help.module').then((m) => m.HelpModule),
      },
    ],
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
