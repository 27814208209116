import { RouterModule } from '@angular/router';
import { AdminGuard } from '../authentication/guards/admin-guard.service';
import { AuthGuard } from '../authentication/guards/auth-guard.service';
import { Pages } from './pages.component';
var ɵ0 = function () { return import("./siteAdmin/areas.module.ngfactory").then(function (m) { return m.AreasModuleNgFactory; }); }, ɵ1 = function () { return import("./operator/stock.module.ngfactory").then(function (m) { return m.StockModuleNgFactory; }); }, ɵ2 = function () { return import("./admin/admin.module.ngfactory").then(function (m) { return m.AdminModuleNgFactory; }); }, ɵ3 = function () { return import("./user-management/user-management.module.ngfactory").then(function (m) { return m.UserManagementModuleNgFactory; }); }, ɵ4 = function () { return import("./profile/profile.module.ngfactory").then(function (m) { return m.ProfileModuleNgFactory; }); }, ɵ5 = function () { return import("./operator/help/help.module.ngfactory").then(function (m) { return m.HelpModuleNgFactory; }); };
export var routes = [
    {
        path: 'pages',
        component: Pages,
        children: [
            { path: '', redirectTo: 'stock', pathMatch: 'full' },
            {
                path: 'areas',
                canActivate: [AuthGuard],
                loadChildren: ɵ0,
            },
            {
                path: 'stock',
                canActivate: [AuthGuard],
                loadChildren: ɵ1,
            },
            {
                path: 'admin',
                canActivate: [AuthGuard],
                loadChildren: ɵ2,
            },
            {
                path: 'user-management',
                canActivate: [AdminGuard],
                loadChildren: ɵ3,
            },
            {
                path: 'profile',
                canActivate: [AuthGuard],
                loadChildren: ɵ4,
            },
            {
                path: 'help',
                canActivate: [AuthGuard],
                loadChildren: ɵ5,
            },
        ],
    },
];
export var routing = RouterModule.forChild(routes);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
