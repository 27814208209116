import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { BoxesService } from "../../../services/boxes.service";
var boxesTable = /** @class */ (function () {
    function boxesTable(boxesService) {
        this.boxesService = boxesService;
        this.boxes = new Array();
        this.name = "";
        this.displayedColumns = ["image", "booking_code", "name", "length", "max_stack"];
        this.dataSource = new MatTableDataSource();
        this.boxesService.loadBoxes();
    }
    Object.defineProperty(boxesTable.prototype, "editable", {
        get: function () { return this._editable; },
        set: function (b) {
            this._editable = b;
        },
        enumerable: true,
        configurable: true
    });
    boxesTable.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.sub = this.boxesService.boxes.subscribe(function (bs) {
            _this.boxes = bs;
            _this.dataSource = new MatTableDataSource(bs);
            _this.dataSource.paginator = _this.paginator;
            _this.dataSource.sort = _this.sort;
        });
    };
    boxesTable.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    boxesTable.prototype.getImage = function (b) {
        var filename = "box_default.png";
        if (b.image)
            filename = b.image;
        return 'assets/img/' + filename;
    };
    boxesTable.prototype.applyFilter = function (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    return boxesTable;
}());
export { boxesTable };
