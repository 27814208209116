import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var JwtInterceptorService = /** @class */ (function () {
    function JwtInterceptorService(authService) {
        this.authService = authService;
        this.AUTH_HEADER = 'Authorization';
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject = new BehaviorSubject(null);
    }
    JwtInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        if (!req.headers.has('Content-Type')) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json'),
            });
        }
        req = this.addAuthenticationToken(req);
        return next.handle(req).pipe(catchError(function (error) {
            if (error && error.status === 401) {
                if (_this.refreshTokenInProgress) {
                    return _this.refreshTokenSubject.pipe(filter(function (result) { return result !== null; }), take(1), switchMap(function () { return next.handle(_this.addAuthenticationToken(req)); }));
                }
                else {
                    _this.refreshTokenInProgress = true;
                    _this.refreshTokenSubject.next(null);
                    return _this.authService.refreshToken().pipe(switchMap(function (tokenData) {
                        window.localStorage.setItem('tokenData', JSON.stringify(tokenData.token));
                        _this.refreshTokenInProgress = false;
                        _this.refreshTokenSubject.next(tokenData);
                        return next.handle(_this.addAuthenticationToken(req));
                    }), catchError(function (err) {
                        _this.authService.resetAuthData();
                        _this.refreshTokenInProgress = false;
                        return throwError(err);
                    }));
                }
            }
            else {
                return throwError(error);
            }
        }));
    };
    JwtInterceptorService.prototype.addAuthenticationToken = function (request) {
        var tokenData = window.localStorage.getItem('tokenData');
        var accessToken = tokenData ? JSON.parse(tokenData).token : null;
        if (!accessToken) {
            return request;
        }
        var skip = request.headers.has('skip');
        if (skip) {
            request = request.clone({ headers: request.headers.delete('Content-Type', 'application/json') });
        }
        return request.clone({
            headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + accessToken),
        });
    };
    JwtInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtInterceptorService_Factory() { return new JwtInterceptorService(i0.ɵɵinject(i1.AuthService)); }, token: JwtInterceptorService, providedIn: "root" });
    return JwtInterceptorService;
}());
export { JwtInterceptorService };
