import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/Rx';
import { UserData, AuthData } from '../../authentication/auth.service';

import { GlobalState } from '../../global.state';
import { ContextService } from '.';
import { ApiService } from '../../api/services';
import { User } from '../../api/models/user';

/**
 * Service for User
 */
@Injectable()
export class UserService {
  private _users: BehaviorSubject<Array<User>> = new BehaviorSubject(Array<User>());
  public readonly users: Observable<Array<User>> = this._users.asObservable();

  public _currentuser: BehaviorSubject<UserData | null> = new BehaviorSubject<UserData | null>(null);
  public readonly currentuser: Observable<UserData | null> = this._currentuser.asObservable();

  constructor(
    private state: GlobalState,
    private contextService: ContextService,
    private api: ApiService,
    private http: HttpClient,
  ) {}

  public changePassword(userData: { token: string; password: string }): Observable<AuthData> {
    return this.http.post<AuthData>('api/auth/changePassword', userData);
  }

  public setCurrentUser(user: UserData) {
    window.localStorage.setItem('currentUser', JSON.stringify(user));
    this._currentuser.next(user);
  }

  public getCurrentUser(): UserData | null {
    return this._currentuser.getValue();
  }
}
