/* tslint:disable */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleEnum } from '../../doks/interfaces/roles';
import { InfoType, SnackBarService } from '../../doks/services/snack-bar.service';
import { UserService } from '../../doks/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(protected router: Router, private userService: UserService, private snackBarService: SnackBarService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.userService._currentuser.pipe(
      map((authenticated) => {
        if (authenticated && this.userService.getCurrentUser()) {
          if (
            this.userService
              .getCurrentUser()
              .roles.some((el: RoleEnum) => [RoleEnum.Admin, RoleEnum.User, RoleEnum.Editor].includes(el as RoleEnum))
          ) {
            return true;
          }
          return (
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url } }),
            this.snackBarService.showBar(1, 'Konto nicht vom Administrator verifiziert', '', InfoType.error)
          );
        }
        return (
          this.router.navigate(['login'], { queryParams: { returnUrl: state.url } }),
          this.snackBarService.showBar(1, 'Konto nicht vom Administrator verifiziert', '', InfoType.error)
        );
      }),
    );
  }
}
