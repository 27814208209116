import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
} from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import * as moment from 'moment/moment';

import { BackendService } from '../../../services/backend.service';
import { ContextService } from '../../../services/context.service';
import { StocktakeService } from '../../../services/stocktake.service';
import { BoxesService } from '../../../services/boxes.service';

import { Box, Stocktake } from '../../../../api/models';

import { GlobalState } from '../../../../global.state';
import { EnvService } from '../../../../env.service';

enum BUTTONTEXT {
  AREA = 'Zuerst Leergutplatz auswählen.',
  NONE = 'Kein Bestand vorhanden.',
  CHOOSE = 'Wähle Bestandsaufnahme',
}

export interface StockList {
  box: Box;
  total_count: number;
}

export interface StockTable {
  multiplied_count: number;
  total_count: number;
  name: string;
  booking_code: string;
}

@Component({
  selector: 'stock-table',
  templateUrl: './stockTable.component.html',
  styleUrls: ['./stockTable.component.scss'],
})
export class stockTable implements OnInit {
  private buttontext: string = BUTTONTEXT.NONE;
  private _stock: Array<StockList> = new Array<StockList>();

  private sub: any;
  public _show: boolean = false;

  private stocktake: Stocktake;

  private displayedColumns: string[] = ['total_count', 'multiplied_count', 'booking_code', 'name'];
  private dataSource = new MatTableDataSource<StockTable>();

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  @Input()
  set stock(s: Array<StockList>) {
    this._stock = s;

    const t: Array<StockTable> = new Array<StockTable>();

    if (s) {
      for (const entry of s) {
        t.push({
          multiplied_count: entry.total_count * entry.box.multiplicator,
          total_count: entry.total_count,
          booking_code: entry.box.booking_code,
          name: entry.box.name,
        });
      }
    }

    this.dataSource = new MatTableDataSource<StockTable>(t);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  get stock(): Array<StockList> {
    return this._stock;
  }

  @Input()
  set show(b: boolean) {
    this._show = b;
  }

  @Output()
  selectedEntry = new EventEmitter<string>();
  private _selected_entry: any;

  constructor(
    private _state: GlobalState,
    private backendService: BackendService,
    private contextService: ContextService,
    private stocktakeService: StocktakeService,
    private boxesService: BoxesService,
    private env: EnvService,
  ) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    // listen to selected area, get stocktakes for them
    this.sub = this.contextService.selectedStocktake$.subscribe((stocktake) => {
      this.stocktake = stocktake;
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectEntry(entry: StockTable): void {
    if (this._selected_entry == entry) {
      this._selected_entry = undefined;
      this.selectedEntry.emit(undefined);
    } else {
      this._selected_entry = entry;
      this.selectedEntry.emit(entry.booking_code);
    }
  }

  getHostname(): string {
    return this.env.hostname;
  }

  getDate(s: Stocktake): string {
    if (!s) {
      return ''
    }
    return moment(s.startdate).format('llll');
  }

  exportToFile() {
    const rows = [
      [
        'LNr',
        'Typ',
        /*'Lagerplatz', 'SpKz',*/ 'Material',
        'Materialkurztext',
        'Stapelhoehe',
        'Zaehlmenge',
        'Kartons',
        'Zuständige Person',
        'Pilot'
      ],
    ];
    this._stock.forEach((element) => {
      const row: Array<any> = [
        element.box.id, // LNr
        element.box.type, // Typ
        // ', // Lagerplatz
        // ', // SpKz
        element.box.booking_code, // Material
        element.box.name  // Materialkurztext
          .replace(/,/g, ' -')
          .replace(/;/g, '. ')
          .replace(/ö/g, 'oe')
          .replace(/ä/g, 'ae')
          .replace(/ü/g, 'ue')
          .replace(/ß/g, 'ss'),
        element.box.max_stack, // Stapelhoehe
        element.total_count, // Zaehlmenge
        element.total_count * element.box.multiplicator, // Kartons
        this.stocktake.user,
        this.stocktake.pilot
        // ' // BME
      ];
      // console.log(row.toString())
      rows.push(row);
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `stocktake_${this.stocktake.id}.csv`);
    document.body.appendChild(link);
    link.click();
  }
}
