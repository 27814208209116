import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  MatButtonModule,
  MatCardModule,
  MatDatepickerModule,
  MatInputModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
} from '@angular/material';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../authentication/auth.service';
import { AuthGuard } from '../authentication/guards/auth-guard.service';
import { TableModule } from './components/table/table.module';

import { LoadScriptDirective } from './directives';

import { stockListFilterPipe } from './pipes';

import { BackendService, ContextService, WebsocketService } from './services';

import {
  areaSelector,
  backendIndicator,
  boxesTable,
  currentLocation,
  gpuIndicator,
  pageTop,
  plannedLayoutSelector,
  reloadWindow,
  sidebar,
  siteSelector,
  stocksList,
  stockSelector,
  stockTable,
  yesNoModal,
} from './components';

const DOKS_COMPONENTS = [
  areaSelector,
  backendIndicator,
  boxesTable,
  currentLocation,
  gpuIndicator,
  pageTop,
  plannedLayoutSelector,
  reloadWindow,
  sidebar,
  siteSelector,
  stockSelector,
  stocksList,
  stockTable,
  yesNoModal,
];

const DOKS_DIRECTIVES = [LoadScriptDirective];

const DOKS_PIPES = [stockListFilterPipe];

const DOKS_SERVICES = [AuthService, BackendService, ContextService, WebsocketService];

const DOKS_VALIDATORS = [];

@NgModule({
  declarations: [...DOKS_PIPES, ...DOKS_DIRECTIVES, ...DOKS_COMPONENTS],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    TableModule,
  ],
  exports: [...DOKS_PIPES, ...DOKS_DIRECTIVES, ...DOKS_COMPONENTS],
})
export class doksModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: doksModule,
      providers: [AuthService, ...DOKS_VALIDATORS, ...DOKS_SERVICES],
    };
  }
}
