import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SnackBarService } from './doks/services/snack-bar.service';
import { EnvServiceProvider } from './env.service.provider';
import { JwtInterceptorService } from './authentication/jwt-interceptor.service';
/*
 * Platform and Environment providers/directives/pipes
 */
import { AppRoutingModule } from './app.routing';
import { environment } from '../environments/environment';

// App is our top level component
import { App } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { ProfileModule } from './pages/profile/profile.module';
import { NgaModule } from './theme/nga.module';
import { doksModule } from './doks/doks.module';
import { PagesModule } from './pages/pages.module';
import { ApiModule } from './api/api.module';
import { LoginModule } from './doks/components/user/login/login.module';

import { ContextService } from './doks/services/context.service';
import { LocationService } from './doks/services/location.service';
import { UserManagementModule } from './pages/user-management/user-management.module';

// Application wide providers
const APP_PROVIDERS = [AppState, GlobalState, ContextService, LocationService, EnvServiceProvider, SnackBarService];

export type StoreType = {
  state: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
};

export function setRootUrl(): string {
  if (window['__env']) {
    return window['__env'].apiUrl;
  } else {
    return environment.apiURL;
  }
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [App],
  imports: [
    // import Angular's modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgaModule.forRoot(),
    NgbModule,
    doksModule.forRoot(),
    ApiModule.forRoot({ rootUrl: environment.apiURL }),
    PagesModule,
    AppRoutingModule,
    MatSnackBarModule,
    UserManagementModule,
    ProfileModule,
  ],
  providers: [
    // expose our Services and Providers into Angular's dependency injection
    APP_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
  ],
})
export class AppModule {
  constructor(public appState: AppState) {}
}
