import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { routing } from './login.routing';

import { Login } from './login.component';
import {
  MatInputModule,
  MatButtonModule,
  MatOptionModule,
  MatSelectModule,
  MatFormFieldModule,
  MatCardModule,
} from '@angular/material';
import { UserService } from '../../../services/user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
  ],
  declarations: [Login],
  providers: [UserService],
})
export class LoginModule {}
