import * as tslib_1 from "tslib";
import { colorHelper } from './theme.constants';
var BaThemeConfigProvider = /** @class */ (function () {
    function BaThemeConfigProvider() {
        this.basic = {
            default: '#ffffff',
            defaultText: '#ffffff',
            border: '#dddddd',
            borderDark: '#aaaaaa',
        };
        // main functional color scheme
        this.colorScheme = {
            primary: '#00abff',
            info: '#40daf1',
            success: '#8bd22f',
            warning: '#e7ba08',
            danger: '#f95372',
        };
        // dashboard colors for charts
        this.dashboardColors = {
            blueStone: '#40daf1',
            surfieGreen: '#00abff',
            silverTree: '#1b70ef',
            gossip: '#3c4eb9',
            white: '#ffffff',
        };
        this.conf = {
            theme: {
                name: 'ng2',
            },
            colors: {
                default: this.basic.default,
                defaultText: this.basic.defaultText,
                border: this.basic.border,
                borderDark: this.basic.borderDark,
                primary: this.colorScheme.primary,
                info: this.colorScheme.info,
                success: this.colorScheme.success,
                warning: this.colorScheme.warning,
                danger: this.colorScheme.danger,
                primaryLight: colorHelper.tint(this.colorScheme.primary, 30),
                infoLight: colorHelper.tint(this.colorScheme.info, 30),
                successLight: colorHelper.tint(this.colorScheme.success, 30),
                warningLight: colorHelper.tint(this.colorScheme.warning, 30),
                dangerLight: colorHelper.tint(this.colorScheme.danger, 30),
                primaryDark: colorHelper.shade(this.colorScheme.primary, 15),
                infoDark: colorHelper.shade(this.colorScheme.info, 15),
                successDark: colorHelper.shade(this.colorScheme.success, 15),
                warningDark: colorHelper.shade(this.colorScheme.warning, 15),
                dangerDark: colorHelper.shade(this.colorScheme.danger, 15),
                dashboard: {
                    blueStone: this.dashboardColors.blueStone,
                    surfieGreen: this.dashboardColors.surfieGreen,
                    silverTree: this.dashboardColors.silverTree,
                    gossip: this.dashboardColors.gossip,
                    white: this.dashboardColors.white,
                },
                custom: {
                    dashboardPieChart: colorHelper.hexToRgbA(this.basic.defaultText, 0.8),
                    dashboardLineChart: this.basic.defaultText,
                },
            },
        };
    }
    BaThemeConfigProvider.prototype.get = function () {
        return this.conf;
    };
    BaThemeConfigProvider.prototype.changeTheme = function (theme) {
        return tslib_1.__assign({}, this.get().theme, theme);
    };
    BaThemeConfigProvider.prototype.changeColors = function (colors) {
        return tslib_1.__assign({}, this.get().theme, colors);
    };
    return BaThemeConfigProvider;
}());
export { BaThemeConfigProvider };
