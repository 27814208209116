/* tslint:disable */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RoleEnum } from '../../doks/interfaces/roles';
import { UserService } from '../../doks/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(protected router: Router, private userService: UserService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.userService._currentuser.pipe(
      map((authenticated) => {
        if (this.userService.getCurrentUser()) {
          return this.userService.getCurrentUser().roles.includes(RoleEnum.Admin);
        }
        return false;
      }),
    );
  }
}
