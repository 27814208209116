import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface DisplayedColumns {
  key: string;
  header: string;
  hasDate?: boolean;
  hasTime?: boolean;
  dateFormat?: string;
}

@Component({
  selector: 'gate-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @Input() dataSource: MatTableDataSource<any>;
  @Input() displayedColumns: DisplayedColumns[];
  @Input() userIdToCheck?: number;

  @Output() details = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() changeAmount = new EventEmitter();

  public displayedColumnsKeys: string[];

  constructor() {}

  public ngOnInit(): void {
    this.displayedColumnsKeys = this.displayedColumns.map((col) => col.key);
  }

  public goToDetails(element: Element) {
    this.details.emit(element);
  }

  public editElement(element: Element) {
    this.edit.emit(element);
  }

  public deleteElement(element: Element) {
    this.delete.emit(element);
  }

  public changeElementAmount(element: Element, decrement: boolean) {
    this.changeAmount.emit({ element, decrement });
  }
}
