import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'yesno-modal',
  templateUrl: './yesnomodal.component.html',
  styleUrls: ['./yesnomodal.component.scss']
})
export class yesNoModal {

  modalHeader: string;
  modalContent: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() { }

  closeModal(result: boolean) {
    this.activeModal.close(result);
  }

}
