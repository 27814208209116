<div class="dashboard-page">
  <form [formGroup]="resetPasswordForm" #formDirective="ngForm">
    <div>
      <mat-form-field>
        <mat-label>Neues Passwort</mat-label>
        <input
          matInput
          autocomplete="new-password"
          name="off"
          type="password"
          placeholder="Neues Passwort"
          [formControlName]="'password'"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Passwort wiederholen</mat-label>
        <input
          matInput
          autocomplete="new-password"
          name="off"
          placeholder="Passwort wiederholen"
          type="password"
          [formControlName]="'confirmPassword'"
        />
      </mat-form-field>
    </div>

    <mat-error *ngIf="resetPasswordForm.get('confirmPassword')?.errors">
      <div *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.mustMatch">Die Passwörter müssen übereinstimmen</div>
    </mat-error>
    <button [disabled]="resetPasswordForm.invalid" class="btn btn-primary confirm-btn" (click)="changePassword(formDirective)">Veränderung</button>
  </form>

</div>
