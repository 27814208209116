import { Component } from '@angular/core';
import { BackendService } from '../../../../services/backend.service'
import { GlobalState } from '../../../../../global.state';

@Component({
  selector: 'gpu-indicator',
  templateUrl: './gpuIndicator.component.html',
  styleUrls: ['./gpuIndicator.component.scss']
})
export class gpuIndicator {

  public network_level: number = 0;
  public show_network_type: number = 0;
  public network_text: string = "gpu nicht verbunden";

  constructor(private _state: GlobalState,
    private backendservice: BackendService) {

    this.backendservice.sendToWebsocketGetCloudClientState()
    this._state.subscribe("gpu.clientstateresult", result => {
      console.log(result)
      this.network_level = result.new_state
      this.calc_levels();
    });

    this.calc_levels();
  }


  calc_levels() {

    if (!this.network_level || this.network_level == 0) {
      this.network_text = "GPU-Status unbekannt";
    }
    else if (this.network_level == 1) {
      this.network_text = "GPU startet";
    }
    else if (this.network_level == 2) {
      this.network_text = "GPU läuft";
    }
    else if (this.network_level == 3) {
      this.network_text = "GPU schließt sich";
    }
    else if (this.network_level == 4) {
      this.network_text = "GPU ist beendet";
    }
    else if (this.network_level == 5) {
      this.network_text = "GPU stoppt";
    }
    else if (this.network_level == 6) {
      this.network_text = "GPU ist gestoppt";
    }
    else {
      this.network_text = "GPU nicht verbunden";
    }

    if (this.network_level == 1)
      this.show_network_type = 1;
    else if (this.network_level == 2)
      this.show_network_type = 2;
    else
      this.show_network_type = 0;
  }
}
