import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material'
import { Box } from '../../../../api/models/box';
import { BoxesService } from "../../../services/boxes.service";

@Component({
  selector: 'boxes-component',
  templateUrl: './boxes.component.html',
})
export class boxesTable {

  public boxes: Array<Box> = new Array<Box>();
  public name: string = "";
  private sub: any;
  private _editable: boolean;

  public displayedColumns: string[] = ["image", "booking_code", "name", "length", "max_stack"]
  public dataSource = new MatTableDataSource<Box>();

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: false })
  sort: MatSort;


  @Input()
  set editable(b: boolean) {
    this._editable = b;
  }
  get editable(): boolean { return this._editable; }

  constructor(private boxesService: BoxesService) {
    this.boxesService.loadBoxes();
  }

  ngAfterViewInit() {
    this.sub = this.boxesService.boxes.subscribe(
      bs => {
        this.boxes = bs;
        this.dataSource = new MatTableDataSource<Box>(bs)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getImage(b: Box): string {
    let filename = "box_default.png";

    if (b.image)
      filename = b.image;

    return 'assets/img/' + filename;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
