import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export const InfoDurationTime = 3000;

export enum InfoType {
  'error' = 1,
  'info' = 2,
  'success' = 3,
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  public showBar(id: number, msg: string, info: string, type: InfoType) {
    const className =
      type === InfoType.info
        ? ['grey-snackbar']
        : type === InfoType.success
        ? ['green-snackbar']
        : type == InfoType.error
        ? ['red-snackbar']
        : ['orange-snackbar'];

    this.snackBar.open(`[${id}] ${msg}`, 'OK', {
      duration: InfoDurationTime,
      panelClass: className,
    });
  }
}
