<div class="dialog">
  <div class="dialog-header">
    <div class="dialog-title">
      {{data.user ? 'Benutzer bearbeiten' : 'Nutzer hinzufügen'}}
    </div>
  </div>
  <div class="dialog-body">
    <form [formGroup]="userDialogFrom">
      <div>
        <mat-form-field>
          <mat-label>Nutzername</mat-label>
          <input
            matInput
            placeholder="Nutzername"
            autocomplete="new-name"
            [formControlName]="'name'"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="userDialogFrom.get('name')?.hasError('exist')">
            Name existiert bereits
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            matInput
            placeholder="Email"
            autocomplete="off"
            [formControlName]="'email'"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="userDialogFrom.get('email')?.hasError('email')">
            Email ist ungültig
          </mat-error>
          <mat-error *ngIf="userDialogFrom.get('email')?.hasError('exist')">
            E-Mail wird bereits verwendet
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Passwort</mat-label>
          <input
            matInput
            autocomplete="new-password"
            name="off"
            placeholder="Passwort"
            type="password"
            [formControlName]="'password'"
          />
        </mat-form-field>
      </div>

      <div>
        <mat-label>Rollen</mat-label>
        <div *ngFor="let role of roles">
          <mat-checkbox [checked]="userDialogFrom.get('roles')?.value.includes(role)"
                        [value]="role"
                        (change)="setRole($event)">
            {{role}}
          </mat-checkbox>
        </div>
      </div>


    </form>
  </div>
  <div class="dialog-actions">
    <button class="btn btn-danger confirm-btn" [mat-dialog-close]="false">Absagen</button>
    <button class="btn btn-primary confirm-btn float-right"
            [mat-dialog-close]="userDialogFrom.value"
            [disabled]="!userDialogFrom.valid">
      Bestätigen
    </button>
  </div>
</div>
