import { environment } from '../environments/environment.vw.baunatal';

export class EnvService {
  // API url
  public apiUrl = environment.apiURL;
  public wsURL = environment.wsURL;
  public hostname = environment.hostname;
  public orthoPath = environment.orthoPath;
  public cloudPath = environment.cloudPath;
  constructor() {
  }

}
