import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { GlobalState } from '../../global.state';

import { ContextService } from './context.service';
import { WebsocketService, wsMessageType } from './websocket.service';

/**
 * BackendService bildet die gesamte Anbindung an die Drohne ab
 */
@Injectable()
export class BackendService {
  private sub_ws: Subscription;

  constructor(
    private _contextService: ContextService,
    private _wsService: WebsocketService,
    private _state: GlobalState,
  ) {
    this.sub_ws = this._wsService.messages.subscribe((message) => {
      console.log(message);
      if (message.t == wsMessageType.ProcessingProgress) {
        console.debug('got new progress msg: ', message);
        this._state.notifyDataChanged('backend.progress', message.d);
      } else if (message.t == wsMessageType.ProcessingResult) {
        console.debug('got result from msg: ', message);
        this._state.notifyDataChanged('backend.result', message.d);
      } else if (message.t == wsMessageType.FlightInfos) {
        console.debug('got flight info from sd card msg: ', message);
        this._state.notifyDataChanged('backend.flightinfos', message.d);
      } else if (message.t == wsMessageType.BlockdeviceState) {
        console.debug('got blockdevice info msg: ', message);
        this._state.notifyDataChanged('backend.blockdevice', message.d);
      } else if (message.t == wsMessageType.CCServiceCloudClientStateResult) {
        console.debug('got client state result msg: ', message);
        this._state.notifyDataChanged('gpu.clientstateresult', message.d);
      } else {
        console.debug('got unknown websocket msg: ', message);
      }
    });
  }

  ngOnDestroy() {
    this.sub_ws.unsubscribe();
  }

  sendToWebsocketGetCleanWorkspace() {
    let msg = {
      t: wsMessageType.CCServiceCleanWorkspace,
      d: {
        dry_run: 'False',
      },
    };
    this._wsService.send(JSON.stringify(msg));
  }

  sendToWebsocketRestartSegmenting(stocktakeid: number) {
    let msg = {
      t: wsMessageType.CCServiceRestartSegmenting,
      d: {
        stocktake_id: stocktakeid,
      },
    };
    this._wsService.send(JSON.stringify(msg));
  }

  sendToWebsocketGetCloudClientState() {
    let msg = {
      t: wsMessageType.CCServiceGetCloudClientState,
      d: {},
    };
    this._wsService.send(JSON.stringify(msg));
  }

  sendToWebsocketServiceAbort() {
    let msg = {
      t: wsMessageType.CCServiceAbort,
      d: {},
    };
    this._wsService.send(JSON.stringify(msg));
  }

  sendToWebsocketFlightInfos(path: string, time_between_flights: number) {
    let msg = {
      t: wsMessageType.GetFlightInfos,
      d: {
        path_to_images: path,
        time_between_flights: time_between_flights,
      },
    };
    console.log(`Message_10:\n${JSON.stringify(msg)}`);
    this._wsService.send(JSON.stringify(msg));
  }

  sendToWebsocketStartProcessing(flight_id: number, stocktake_id: number) {
    let msg = {
      t: wsMessageType.StartProcessing,
      d: {
        flight_id: flight_id,
        stocktake_id: stocktake_id,
      },
    };
    this._wsService.send(JSON.stringify(msg));
  }

  sendToWebsocketRecalc(stocktake_id: number, addrois_list: any, add_list: any, edit_list: any, delete_list: any) {
    let msg = {
      t: wsMessageType.StartReprocess,
      d: {
        stocktake_id: stocktake_id,
        addrois: addrois_list,
        add: add_list,
        edit: edit_list,
        delete: delete_list,
      },
    };
    this._wsService.send(JSON.stringify(msg));
  }

  sendToWebsocketExport(stocktake_id: number) {
    let msg = {
      t: wsMessageType.StartExport,
      d: {
        stocktake_id: stocktake_id,
      },
    };
    this._wsService.send(JSON.stringify(msg));
  }

  sendToWebsocketGetBlockdeviceInfo() {
    let msg = {
      t: wsMessageType.GetBlockdeviceState,
      d: {},
    };
    this._wsService.send(JSON.stringify(msg));
  }
}
