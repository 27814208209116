import { Component } from '@angular/core';

import { Area, Place as Location, Site } from "../../../../../api/models"

import { ContextService } from '../../../../services/context.service';
import { GlobalState } from '../../../../../global.state';

@Component({
  selector: 'current-location',
  templateUrl: './currentLocation.component.html',
  styleUrls: ['./currentLocation.component.scss']
})
export class currentLocation {

  public location_title: string = "";
  public location_subtitle: string = "";

  private area: Area;
  private site: Site;
  private location: Location;

  constructor(private _state: GlobalState,
    private contextService: ContextService) {
    this._state.subscribe('context.LocationSelected', (location) => {
      this.location = location;
      this.updateText();
    });

    this._state.subscribe('context.SiteSelected', (site) => {
      this.site = site;
      this.updateText();
    });

    this._state.subscribe('context.AreaSelected', (area) => {
      this.area = area;
      this.updateText();
    });

    if (this.contextService.area) {
      this.area = this.contextService.area;
      this.updateText();
    }

    if (this.contextService.site) {
      this.site = this.contextService.site;
      this.updateText();
    }

    if (this.contextService.location) {
      this.location = this.contextService.location;
      this.updateText();
    }

  }

  updateText() {
    this.location_title = (this.location ? this.location.name : "");
    //this.location_subtitle = this.location.city + ', ' + this.location.country;
    this.location_subtitle = (this.site ? this.site.name : "") + " " + (this.area ? this.area.description : "");
  }

}
