import { Subject, BehaviorSubject } from 'rxjs';
import { ApiService } from '../../api/services';
import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';
/**
 * ContextService, hold user selected contexts, e.g. location, building
 */
var ContextService = /** @class */ (function () {
    function ContextService(_state, api) {
        this._state = _state;
        this.api = api;
        // Observable building and location sources
        this.selectedActiveStepSource = new Subject();
        this.selectedAreaSource = new Subject();
        this.selectedSiteSource = new BehaviorSubject(undefined);
        this.selectedLocationSource = new Subject();
        this.selectedPlannedLayoutSource = new Subject();
        this.selectedStocktakeSource = new Subject();
        // Observable string streams
        this.selectedActiveStep$ = this.selectedActiveStepSource.asObservable();
        this.selectedArea$ = this.selectedAreaSource.asObservable();
        this.selectedSite$ = this.selectedSiteSource.asObservable();
        this.selectedLocation$ = this.selectedLocationSource.asObservable();
        this.selectedPlannedLayout$ = this.selectedPlannedLayoutSource.asObservable();
        this.selectedStocktake$ = this.selectedStocktakeSource.asObservable();
        this._location = {
            id: environment.locationId,
            activated: true,
            sites: undefined,
            city: undefined,
            comment: undefined,
            country: undefined,
            countrycode: undefined,
            latitude: undefined,
            longitude: undefined,
            map_marker: undefined,
            name: undefined,
            postalcode: undefined,
            street: undefined,
        };
        this.context = { activeStep: 3, siteId: 1, locationId: 1, plannedlayoutId: 1, areaId: 17, stocktakeId: 227 };
        /**api.ContextServiceGetGUIContext(0).subscribe(res => {
          this.context = res;
          this.activeStep = res.activeStep;
        });*/
    }
    Object.defineProperty(ContextService.prototype, "activeStep", {
        /**
         Active step of user wizard
         */
        get: function () {
            return this._activeStep;
        },
        set: function (n) {
            /**this.api.ContextServiceUpdateActiveStep({ id: 0, newstep: { data: n } }).toPromise().then(
              res => {
              },
              err => {
                console.error("error saving Activestep")
              }
            );*/
            this._activeStep = n;
            this._state.notifyDataChanged('context.ActiveStepSelected', n);
            this.selectedActiveStepSource.next(n);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextService.prototype, "area", {
        /**
         Current selected area (e.g. "Leergutplatz H52")
         */
        get: function () {
            return this._area;
        },
        set: function (a) {
            var id = undefined;
            if (a)
                id = a.id;
            /**this.api.ContextServiceUpdateArea({ id: 0, newId: { data: id } }).toPromise().then(
              res => {
              },
              err => {
                console.error("error saving Area")
              }
            );*/
            this._area = a;
            this._state.notifyDataChanged('context.AreaSelected', a);
            this.selectedAreaSource.next(a);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextService.prototype, "site", {
        /**
       Current selected site
       */
        get: function () {
            return this._site;
        },
        set: function (s) {
            var id = undefined;
            if (s)
                id = s.id;
            this.context.siteId = id;
            //@TODO
            /**this.api.ContextServiceUpdateSite({ id: 0, newId: { data: id } }).toPromise().then(
              res => {
              },
              err => {
                console.error("error saving Site")
              }
            );*/
            this._site = s;
            this._state.notifyDataChanged('context.SiteSelected', s);
            this.selectedSiteSource.next(s);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextService.prototype, "location", {
        /**
         Current selected location (e.g. "BMW Werk Leipzig")
         */
        get: function () {
            return this._location;
        },
        set: function (loc) {
            var id = undefined;
            if (loc)
                id = loc.id;
            /**this.api.ContextServiceUpdateLocation({ id: 0, newId: { data: id } }).toPromise().then(
              res => {
              },
              err => {
                console.error("error saving location");
              }
            );*/
            this._location = loc;
            this._state.notifyDataChanged('context.LocationSelected', loc);
            this.selectedLocationSource.next(loc);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextService.prototype, "plannedlayout", {
        /**
         Current selected planned layout (e.g. "SOLL Belegung Januar 2018")
         */
        get: function () {
            return this._plannedlayout;
        },
        set: function (p) {
            var id = undefined;
            if (p)
                id = p.id;
            /**this.api.ContextServiceUpdatePlannedLayout({ id: 0, newId: { data: id } }).toPromise().then(
              res => {
              },
              err => {
                console.error("error saving PlannedLayout");
              }
            );*/
            this._plannedlayout = p;
            this._state.notifyDataChanged('context.PlannedLayoutSelected', p);
            this.selectedPlannedLayoutSource.next(p);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContextService.prototype, "stocktake", {
        /**
         Current selected stocktake user is looking at or creating
         */
        get: function () {
            return this._stocktake;
        },
        set: function (s) {
            var id = undefined;
            if (s)
                id = s.id;
            /**this.api.ContextServiceUpdateStocktake({ id: 0, newId: { data: id } }).toPromise().then(
              res => {
              },
              err => {
                console.error("error saving stocktake")
              }
            );*/
            this._stocktake = s;
            this._state.notifyDataChanged('context.StocktakeSelected', s);
            this.selectedStocktakeSource.next(s);
        },
        enumerable: true,
        configurable: true
    });
    return ContextService;
}());
export { ContextService };
