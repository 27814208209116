import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/Rx';

import { ApiService } from '../../api/services';
import { PlannedLayout } from '../../api/models';

/**
 * Service for PlannedLayouts
 */
@Injectable()
export class PlannedLayoutService {
  private _list: BehaviorSubject<Array<PlannedLayout>> = new BehaviorSubject(Array<PlannedLayout>());
  public readonly plannedlayouts: Observable<Array<PlannedLayout>> = this._list.asObservable();

  constructor(private api: ApiService) {
    // let service load all layouts
    this.loadPlannedLayouts();
  }

  /**
   * Trigger retrieval of all plannedlayouts from database
   */
  loadPlannedLayouts(): void {
    this.api.PlannedLayoutServiceGetPlannedLayouts('').subscribe(
      (v) => {
        this._list.next(v);
      },
      (error) => {
        console.error('PlannedLayoutService error retrieving list', error);
      },
    );
  }

  getPlannedLayout(id: number): PlannedLayout {
    for (let a of this._list.value) {
      if (a.id == id) {
        return a;
      }
    }
    return undefined;
  }

  getPlannedLayoutsOfArea(id: number): Array<PlannedLayout> {
    let res: Array<PlannedLayout> = new Array<PlannedLayout>();
    for (let a of this._list.value) {
      if (!a || !a.area) continue;

      if (a.area.id == id) {
        res.push(a);
      }
    }
    return res;
  }

  addPlannedLayout(p: PlannedLayout): Promise<PlannedLayout> {
    return new Promise<PlannedLayout>((resolve, reject) => {
      try {
        console.debug('REST add plannedlayout...');
        this.api.PlannedLayoutServiceAddPlannedLayout(p).subscribe(
          (res) => {
            this._list.getValue().push(res);
            this._list.next(this._list.getValue());
            return resolve(res);
          },
          (err) => {
            console.error('error adding plannedlayout');
            return reject(err);
          },
        );
      } catch (err) {
        console.error('error adding plannedlayout:', err);
        return reject(err);
      }
    });
  }

  updatePlannedLayout(p: any): Promise<PlannedLayout> {
    return new Promise<PlannedLayout>((resolve, reject) => {
      try {
        console.debug('REST update plannedlayout...');
        this.api
          .PlannedLayoutServiceUpdatePlannedLayout({
            id: p.id,
            plannedlayout: p,
          })
          .subscribe(
            (res) => {
              console.debug('Updated plannedlayout');
              return resolve(p);
            },
            (err) => {
              console.error('error updating plannedlayout');
              return reject(err);
            },
          );
      } catch (err) {
        console.error('error updating plannedlayout:', err);
        return reject(err);
      }
    });
  }

  deletePlannedLayout(p: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        console.debug('REST delete plannedlayout...');
        this.api.PlannedLayoutServiceDeletePlannedLayout(p.id).subscribe(
          (res) => {
            // remove item on success
            this._list.getValue().forEach((item, index) => {
              if (item === p) this._list.getValue().splice(index, 1);
            });
            // notify changes
            this._list.next(this._list.getValue());
            return resolve();
          },
          (err) => {
            console.error('error deleting plannedlayout', err);
            return reject();
          },
        );
      } catch (err) {
        console.error('error deleting plannedlayout:', err);
        return reject();
      }
    });
  }
}
