import { OnInit, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
var TableComponent = /** @class */ (function () {
    function TableComponent() {
        this.details = new EventEmitter();
        this.edit = new EventEmitter();
        this.delete = new EventEmitter();
        this.changeAmount = new EventEmitter();
    }
    Object.defineProperty(TableComponent.prototype, "matSort", {
        // @ts-ignore
        set: function (sort) {
            this.dataSource.sort = sort;
        },
        enumerable: true,
        configurable: true
    });
    TableComponent.prototype.ngOnInit = function () {
        this.displayedColumnsKeys = this.displayedColumns.map(function (col) { return col.key; });
    };
    TableComponent.prototype.goToDetails = function (element) {
        this.details.emit(element);
    };
    TableComponent.prototype.editElement = function (element) {
        this.edit.emit(element);
    };
    TableComponent.prototype.deleteElement = function (element) {
        this.delete.emit(element);
    };
    TableComponent.prototype.changeElementAmount = function (element, decrement) {
        this.changeAmount.emit({ element: element, decrement: decrement });
    };
    return TableComponent;
}());
export { TableComponent };
