import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { UserData } from '../../../authentication/auth.service';
import { yesNoModal } from '../../../doks/components/modals';
import { DisplayedColumns } from '../../../doks/components/table/table.component';
import { SnackBarService, InfoType } from '../../../doks/services/snack-bar.service';
import { UserService } from '../../../doks/services/user.service';
import { UserManagementDialogComponent } from '../modals/user-management-dialog/user-management-dialog.component';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'service-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private subs: SubSink = new SubSink();
  public dataSource = new MatTableDataSource<UserData>();
  public currentUser: UserData;
  public users: UserData[];

  displayedColumns: DisplayedColumns[] = [
    {
      key: 'email',
      header: 'Email',
    },
    {
      key: 'name',
      header: 'Name',
    },
    {
      key: 'created_at',
      header: 'Created at',
      dateFormat: 'dd/MM/yyyy',
    },
    {
      key: 'roles',
      header: 'Roles',
    },
    {
      key: 'actions',
      header: 'Actions',
    },
  ];

  constructor(
    private userService: UserService,
    private userManagementService: UserManagementService,
    private router: Router,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.subs.sink = this.userService._currentuser.pipe(filter((user) => !!user)).subscribe((user) => {
      this.currentUser = user as UserData;
    });

    this.subs.sink = this.userManagementService.getUsers().subscribe((users: UserData[]) => {
      this.users = users;
      this.dataSource.data = this.users;
      this.dataSource.sort = this.sort;
    });
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public editUser(user: UserData): void {
    if (!this.isUserHasId(user)) {
      return;
    }

    this.dialog
      .open(UserManagementDialogComponent, {
        width: '400px',
        data: { user, users: this.users },
      })
      .afterClosed()
      .pipe(
        filter((newUser) => !!newUser),
        switchMap((newUser: UserData) => {
          newUser.id = user.id;
          if (!newUser.password) {
            delete newUser.password;
          }
          return this.userManagementService.updateUser(newUser);
        }),
      )
      .subscribe(
        (updatedUser) => {
          const index = this.dataSource.data.findIndex((userData) => userData.id === updatedUser.id);
          this.dataSource.data[index] = updatedUser;
          this.dataSource.data = [...this.dataSource.data];
          if (this.currentUser && this.currentUser.id === updatedUser.id) {
            this.userService.setCurrentUser(updatedUser);
          }
          this.snackBarService.showBar(1, 'Benutzer wurde aktualisiert', '', InfoType.success);
        },
        (err) => {
          this.snackBarService.showBar(3, `${err.error}`, err, InfoType.error);
        },
      );
  }

  public addUser(): void {
    this.dialog
      .open(UserManagementDialogComponent, {
        width: '400px',
        data: { user: null, users: this.users },
      })
      .afterClosed()
      .pipe(
        filter((newUser) => !!newUser),
        switchMap((newUser: UserData) => {
          return this.userManagementService.addUser(newUser);
        }),
      )
      .subscribe(
        (newUser) => {
          this.dataSource.data = [...this.dataSource.data, newUser];
          this.snackBarService.showBar(1, 'Benutzer wurde hinzugefügt', '', InfoType.success);
        },
        (err) => {
          this.snackBarService.showBar(3, `${err.error}`, err, InfoType.error);
        },
      );
  }

  public deleteUser(user: UserData): void {
    if (!this.isUserHasId(user)) {
      return;
    }
    const yesnoModal = this.modalService.open(yesNoModal, { size: 'lg' });
    yesnoModal.componentInstance.modalHeader = 'Bestehendes Layout duplizieren';
    yesnoModal.componentInstance.modalContent =
      'Soll das aktuell ausgewählte Layout als Vorlage für ein neues Layout gewählt werden?';
    yesnoModal.result
      .then((result: boolean) => {
        if (result) {
          this.userManagementService.deleteUser(user.id as number).subscribe(
            () => {
              this.dataSource.data = this.dataSource.data.filter((data) => data.id !== user.id);
              this.snackBarService.showBar(1, 'User was deleted', '', InfoType.success);
            },
            (err) => {
              this.snackBarService.showBar(3, `${err.error.message}`, err, InfoType.error);
            },
          );
        }
      })
      .catch((err) => {
        this.snackBarService.showBar(3, `${err.error.message}`, err, InfoType.error);
      });
  }

  private isUserHasId(user: UserData): number | null {
    return user.id ? user.id : null;
  }
}
