import { Component } from '@angular/core';
import { Routes } from '@angular/router';

import { BaMenuService } from '../theme';
import { ContextService } from '../doks/services/context.service';
import { PAGES_MENU } from './pages.menu';

@Component({
  selector: 'pages',
  template: `
    <sidebar></sidebar>
    <page-top></page-top>
    <div class="al-main">
      <div class="al-content">
        <router-outlet></router-outlet>
      </div>
    </div>
    `
})
export class Pages {

  constructor(private _menuService: BaMenuService,
    private contextService: ContextService) {
  }

  ngOnInit() {
    this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
  }
}
