/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Area } from '../models/area';
import { Box } from '../models/box';
import { BoxElement } from '../models/box-element';
import { FoundPosition } from '../models/found-position';
import { GUIContext } from '../models/guicontext';
import { MarkerConfiguration } from '../models/marker-configuration';
import { Place } from '../models/place';
import { PlannedLayout } from '../models/planned-layout';
import { ReferenceTransform } from '../models/reference-transform';
import { Role } from '../models/role';
import { Site } from '../models/site';
import { Stocktake } from '../models/stocktake';
import { StocktakeToArea } from '../models/stocktake-to-area';
import { User } from '../models/user';
import { UserAuthData } from '../models/user-auth-data';
@Injectable({
  providedIn: 'root',
})
class ApiService extends __BaseService {
  static readonly AuthServiceLoginPath = '/auth/login';
  static readonly AreaServiceGetAreasPath = '/areas';
  static readonly AreaServiceAddAreaPath = '/areas';
  static readonly AreaServiceGetAreaPath = '/areas/{id}';
  static readonly AreaServiceDeleteAreaPath = '/areas/{id}';
  static readonly AreaServiceUpdateAreaPath = '/areas/{id}';
  static readonly BoxServiceGetBoxsPath = '/boxs';
  static readonly BoxServiceAddBoxPath = '/boxs';
  static readonly BoxServiceGetBoxPath = '/boxs/{id}';
  static readonly BoxServiceDeleteBoxPath = '/boxs/{id}';
  static readonly BoxServiceUpdateBoxPath = '/boxs/{id}';
  static readonly BoxElementServiceGetBoxElementsPath = '/boxelements';
  static readonly BoxElementServiceAddBoxElementPath = '/boxelements';
  static readonly BoxElementServiceGetBoxElementPath = '/boxelements/{id}';
  static readonly BoxElementServiceDeleteBoxElementPath = '/boxelements/{id}';
  static readonly BoxElementServiceUpdateBoxElementPath = '/boxelements/{id}';
  static readonly FoundPositionServiceGetFoundPositionsPath = '/foundpositions';
  static readonly FoundPositionServiceAddFoundPositionPath = '/foundpositions';
  static readonly FoundPositionServiceGetFoundPositionPath = '/foundpositions/{id}';
  static readonly FoundPositionServiceDeleteFoundPositionPath = '/foundpositions/{id}';
  static readonly FoundPositionServiceUpdateFoundPositionPath = '/foundpositions/{id}';
  static readonly GUIContextServiceGetGUIContextsPath = '/guicontexts';
  static readonly GUIContextServiceAddGUIContextPath = '/guicontexts';
  static readonly GUIContextServiceGetGUIContextPath = '/guicontexts/{id}';
  static readonly GUIContextServiceDeleteGUIContextPath = '/guicontexts/{id}';
  static readonly GUIContextServiceUpdateGUIContextPath = '/guicontexts/{id}';
  static readonly MarkerConfigurationServiceGetMarkerConfigurationsPath = '/markerconfigurations';
  static readonly MarkerConfigurationServiceAddMarkerConfigurationPath = '/markerconfigurations';
  static readonly MarkerConfigurationServiceGetMarkerConfigurationPath = '/markerconfigurations/{id}';
  static readonly MarkerConfigurationServiceDeleteMarkerConfigurationPath = '/markerconfigurations/{id}';
  static readonly MarkerConfigurationServiceUpdateMarkerConfigurationPath = '/markerconfigurations/{id}';
  static readonly PlaceServiceGetPlacesPath = '/places';
  static readonly PlaceServiceAddPlacePath = '/places';
  static readonly PlaceServiceGetPlacePath = '/places/{id}';
  static readonly PlaceServiceDeletePlacePath = '/places/{id}';
  static readonly PlaceServiceUpdatePlacePath = '/places/{id}';
  static readonly PlannedLayoutServiceGetPlannedLayoutsPath = '/plannedlayouts';
  static readonly PlannedLayoutServiceAddPlannedLayoutPath = '/plannedlayouts';
  static readonly PlannedLayoutServiceGetPlannedLayoutPath = '/plannedlayouts/{id}';
  static readonly PlannedLayoutServiceDeletePlannedLayoutPath = '/plannedlayouts/{id}';
  static readonly PlannedLayoutServiceUpdatePlannedLayoutPath = '/plannedlayouts/{id}';
  static readonly ReferenceTransformServiceGetReferenceTransformsPath = '/referencetransforms';
  static readonly ReferenceTransformServiceAddReferenceTransformPath = '/referencetransforms';
  static readonly ReferenceTransformServiceGetReferenceTransformPath = '/referencetransforms/{id}';
  static readonly ReferenceTransformServiceDeleteReferenceTransformPath = '/referencetransforms/{id}';
  static readonly ReferenceTransformServiceUpdateReferenceTransformPath = '/referencetransforms/{id}';
  static readonly RoleServiceGetRolesPath = '/roles';
  static readonly RoleServiceAddRolePath = '/roles';
  static readonly RoleServiceGetRolePath = '/roles/{id}';
  static readonly RoleServiceDeleteRolePath = '/roles/{id}';
  static readonly RoleServiceUpdateRolePath = '/roles/{id}';
  static readonly SiteServiceGetSitesPath = '/sites';
  static readonly SiteServiceAddSitePath = '/sites';
  static readonly SiteServiceGetSitePath = '/sites/{id}';
  static readonly SiteServiceDeleteSitePath = '/sites/{id}';
  static readonly SiteServiceUpdateSitePath = '/sites/{id}';
  static readonly StocktakeServiceGetStocktakesPath = '/stocktakes';
  static readonly StocktakeServiceAddStocktakePath = '/stocktakes';
  static readonly StocktakeServiceGetStocktakePath = '/stocktakes/{id}';
  static readonly StocktakeServiceDeleteStocktakePath = '/stocktakes/{id}';
  static readonly StocktakeServiceUpdateStocktakePath = '/stocktakes/{id}';
  static readonly StocktakeToAreaServiceGetStocktakeToAreasPath = '/stocktaketoareas';
  static readonly StocktakeToAreaServiceAddStocktakeToAreaPath = '/stocktaketoareas';
  static readonly StocktakeToAreaServiceGetStocktakeToAreaPath = '/stocktaketoareas/{id}';
  static readonly StocktakeToAreaServiceDeleteStocktakeToAreaPath = '/stocktaketoareas/{id}';
  static readonly StocktakeToAreaServiceUpdateStocktakeToAreaPath = '/stocktaketoareas/{id}';
  static readonly UserServiceGetUsersPath = '/users';
  static readonly UserServiceAddUserPath = '/users';
  static readonly UserServiceGetUserPath = '/users/{id}';
  static readonly UserServiceDeleteUserPath = '/users/{id}';
  static readonly UserServiceUpdateUserPath = '/users/{id}';
  static readonly UserAuthDataServiceGetUserAuthDatasPath = '/userauthdatas';
  static readonly UserAuthDataServiceAddUserAuthDataPath = '/userauthdatas';
  static readonly UserAuthDataServiceGetUserAuthDataPath = '/userauthdatas/{id}';
  static readonly UserAuthDataServiceDeleteUserAuthDataPath = '/userauthdatas/{id}';
  static readonly UserAuthDataServiceUpdateUserAuthDataPath = '/userauthdatas/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * login with one userid and password
   * @param logincontent
   * @return Ok
   */
  AuthServiceLoginResponse(logincontent: {}): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = logincontent;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * login with one userid and password
   * @param logincontent
   * @return Ok
   */
  AuthServiceLogin(logincontent: {}): __Observable<boolean> {
    return this.AuthServiceLoginResponse(logincontent).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * get all area
   * @param options
   * @return Ok
   */
  AreaServiceGetAreasResponse(options: string): __Observable<__StrictHttpResponse<Array<Area>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/areas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Area>>;
      })
    );
  }
  /**
   * get all area
   * @param options
   * @return Ok
   */
  AreaServiceGetAreas(options: string): __Observable<Array<Area>> {
    return this.AreaServiceGetAreasResponse(options).pipe(
      __map(_r => _r.body as Array<Area>)
    );
  }

  /**
   * @param area
   * @return Ok
   */
  AreaServiceAddAreaResponse(area: Area): __Observable<__StrictHttpResponse<Area>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = area;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/areas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Area>;
      })
    );
  }
  /**
   * @param area
   * @return Ok
   */
  AreaServiceAddArea(area: Area): __Observable<Area> {
    return this.AreaServiceAddAreaResponse(area).pipe(
      __map(_r => _r.body as Area)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  AreaServiceGetAreaResponse(id: number): __Observable<__StrictHttpResponse<Area>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/areas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Area>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  AreaServiceGetArea(id: number): __Observable<Area> {
    return this.AreaServiceGetAreaResponse(id).pipe(
      __map(_r => _r.body as Area)
    );
  }

  /**
   * @param id
   */
  AreaServiceDeleteAreaResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/areas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  AreaServiceDeleteArea(id: number): __Observable<null> {
    return this.AreaServiceDeleteAreaResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.AreaServiceUpdateAreaParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `area`:
   *
   * @return Ok
   */
  AreaServiceUpdateAreaResponse(params: ApiService.AreaServiceUpdateAreaParams): __Observable<__StrictHttpResponse<Area>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.area;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/areas/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Area>;
      })
    );
  }
  /**
   * @param params The `ApiService.AreaServiceUpdateAreaParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `area`:
   *
   * @return Ok
   */
  AreaServiceUpdateArea(params: ApiService.AreaServiceUpdateAreaParams): __Observable<Area> {
    return this.AreaServiceUpdateAreaResponse(params).pipe(
      __map(_r => _r.body as Area)
    );
  }

  /**
   * get all box
   * @param options
   * @return Ok
   */
  BoxServiceGetBoxsResponse(options: string): __Observable<__StrictHttpResponse<Array<Box>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/boxs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Box>>;
      })
    );
  }
  /**
   * get all box
   * @param options
   * @return Ok
   */
  BoxServiceGetBoxs(options: string): __Observable<Array<Box>> {
    return this.BoxServiceGetBoxsResponse(options).pipe(
      __map(_r => _r.body as Array<Box>)
    );
  }

  /**
   * @param box
   * @return Ok
   */
  BoxServiceAddBoxResponse(box: Box): __Observable<__StrictHttpResponse<Box>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = box;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/boxs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Box>;
      })
    );
  }
  /**
   * @param box
   * @return Ok
   */
  BoxServiceAddBox(box: Box): __Observable<Box> {
    return this.BoxServiceAddBoxResponse(box).pipe(
      __map(_r => _r.body as Box)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  BoxServiceGetBoxResponse(id: number): __Observable<__StrictHttpResponse<Box>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/boxs/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Box>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  BoxServiceGetBox(id: number): __Observable<Box> {
    return this.BoxServiceGetBoxResponse(id).pipe(
      __map(_r => _r.body as Box)
    );
  }

  /**
   * @param id
   */
  BoxServiceDeleteBoxResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/boxs/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  BoxServiceDeleteBox(id: number): __Observable<null> {
    return this.BoxServiceDeleteBoxResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.BoxServiceUpdateBoxParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `box`:
   *
   * @return Ok
   */
  BoxServiceUpdateBoxResponse(params: ApiService.BoxServiceUpdateBoxParams): __Observable<__StrictHttpResponse<Box>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.box;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/boxs/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Box>;
      })
    );
  }
  /**
   * @param params The `ApiService.BoxServiceUpdateBoxParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `box`:
   *
   * @return Ok
   */
  BoxServiceUpdateBox(params: ApiService.BoxServiceUpdateBoxParams): __Observable<Box> {
    return this.BoxServiceUpdateBoxResponse(params).pipe(
      __map(_r => _r.body as Box)
    );
  }

  /**
   * get all boxelement
   * @param options
   * @return Ok
   */
  BoxElementServiceGetBoxElementsResponse(options: string): __Observable<__StrictHttpResponse<Array<BoxElement>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/boxelements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BoxElement>>;
      })
    );
  }
  /**
   * get all boxelement
   * @param options
   * @return Ok
   */
  BoxElementServiceGetBoxElements(options: string): __Observable<Array<BoxElement>> {
    return this.BoxElementServiceGetBoxElementsResponse(options).pipe(
      __map(_r => _r.body as Array<BoxElement>)
    );
  }

  /**
   * @param boxelement
   * @return Ok
   */
  BoxElementServiceAddBoxElementResponse(boxelement: BoxElement): __Observable<__StrictHttpResponse<BoxElement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = boxelement;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/boxelements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoxElement>;
      })
    );
  }
  /**
   * @param boxelement
   * @return Ok
   */
  BoxElementServiceAddBoxElement(boxelement: BoxElement): __Observable<BoxElement> {
    return this.BoxElementServiceAddBoxElementResponse(boxelement).pipe(
      __map(_r => _r.body as BoxElement)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  BoxElementServiceGetBoxElementResponse(id: number): __Observable<__StrictHttpResponse<BoxElement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/boxelements/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoxElement>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  BoxElementServiceGetBoxElement(id: number): __Observable<BoxElement> {
    return this.BoxElementServiceGetBoxElementResponse(id).pipe(
      __map(_r => _r.body as BoxElement)
    );
  }

  /**
   * @param id
   */
  BoxElementServiceDeleteBoxElementResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/boxelements/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  BoxElementServiceDeleteBoxElement(id: number): __Observable<null> {
    return this.BoxElementServiceDeleteBoxElementResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.BoxElementServiceUpdateBoxElementParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `boxelement`:
   *
   * @return Ok
   */
  BoxElementServiceUpdateBoxElementResponse(params: ApiService.BoxElementServiceUpdateBoxElementParams): __Observable<__StrictHttpResponse<BoxElement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.boxelement;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/boxelements/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoxElement>;
      })
    );
  }
  /**
   * @param params The `ApiService.BoxElementServiceUpdateBoxElementParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `boxelement`:
   *
   * @return Ok
   */
  BoxElementServiceUpdateBoxElement(params: ApiService.BoxElementServiceUpdateBoxElementParams): __Observable<BoxElement> {
    return this.BoxElementServiceUpdateBoxElementResponse(params).pipe(
      __map(_r => _r.body as BoxElement)
    );
  }

  /**
   * get all foundposition
   * @param options
   * @return Ok
   */
  FoundPositionServiceGetFoundPositionsResponse(options: string): __Observable<__StrictHttpResponse<Array<FoundPosition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/foundpositions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FoundPosition>>;
      })
    );
  }
  /**
   * get all foundposition
   * @param options
   * @return Ok
   */
  FoundPositionServiceGetFoundPositions(options: string): __Observable<Array<FoundPosition>> {
    return this.FoundPositionServiceGetFoundPositionsResponse(options).pipe(
      __map(_r => _r.body as Array<FoundPosition>)
    );
  }

  /**
   * @param foundposition
   * @return Ok
   */
  FoundPositionServiceAddFoundPositionResponse(foundposition: FoundPosition): __Observable<__StrictHttpResponse<FoundPosition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = foundposition;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/foundpositions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FoundPosition>;
      })
    );
  }
  /**
   * @param foundposition
   * @return Ok
   */
  FoundPositionServiceAddFoundPosition(foundposition: FoundPosition): __Observable<FoundPosition> {
    return this.FoundPositionServiceAddFoundPositionResponse(foundposition).pipe(
      __map(_r => _r.body as FoundPosition)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  FoundPositionServiceGetFoundPositionResponse(id: number): __Observable<__StrictHttpResponse<FoundPosition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/foundpositions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FoundPosition>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  FoundPositionServiceGetFoundPosition(id: number): __Observable<FoundPosition> {
    return this.FoundPositionServiceGetFoundPositionResponse(id).pipe(
      __map(_r => _r.body as FoundPosition)
    );
  }

  /**
   * @param id
   */
  FoundPositionServiceDeleteFoundPositionResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/foundpositions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  FoundPositionServiceDeleteFoundPosition(id: number): __Observable<null> {
    return this.FoundPositionServiceDeleteFoundPositionResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.FoundPositionServiceUpdateFoundPositionParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `foundposition`:
   *
   * @return Ok
   */
  FoundPositionServiceUpdateFoundPositionResponse(params: ApiService.FoundPositionServiceUpdateFoundPositionParams): __Observable<__StrictHttpResponse<FoundPosition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.foundposition;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/foundpositions/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FoundPosition>;
      })
    );
  }
  /**
   * @param params The `ApiService.FoundPositionServiceUpdateFoundPositionParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `foundposition`:
   *
   * @return Ok
   */
  FoundPositionServiceUpdateFoundPosition(params: ApiService.FoundPositionServiceUpdateFoundPositionParams): __Observable<FoundPosition> {
    return this.FoundPositionServiceUpdateFoundPositionResponse(params).pipe(
      __map(_r => _r.body as FoundPosition)
    );
  }

  /**
   * get all guicontext
   * @param options
   * @return Ok
   */
  GUIContextServiceGetGUIContextsResponse(options: string): __Observable<__StrictHttpResponse<Array<GUIContext>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/guicontexts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GUIContext>>;
      })
    );
  }
  /**
   * get all guicontext
   * @param options
   * @return Ok
   */
  GUIContextServiceGetGUIContexts(options: string): __Observable<Array<GUIContext>> {
    return this.GUIContextServiceGetGUIContextsResponse(options).pipe(
      __map(_r => _r.body as Array<GUIContext>)
    );
  }

  /**
   * @param guicontext
   * @return Ok
   */
  GUIContextServiceAddGUIContextResponse(guicontext: GUIContext): __Observable<__StrictHttpResponse<GUIContext>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = guicontext;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/guicontexts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GUIContext>;
      })
    );
  }
  /**
   * @param guicontext
   * @return Ok
   */
  GUIContextServiceAddGUIContext(guicontext: GUIContext): __Observable<GUIContext> {
    return this.GUIContextServiceAddGUIContextResponse(guicontext).pipe(
      __map(_r => _r.body as GUIContext)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  GUIContextServiceGetGUIContextResponse(id: number): __Observable<__StrictHttpResponse<GUIContext>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/guicontexts/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GUIContext>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  GUIContextServiceGetGUIContext(id: number): __Observable<GUIContext> {
    return this.GUIContextServiceGetGUIContextResponse(id).pipe(
      __map(_r => _r.body as GUIContext)
    );
  }

  /**
   * @param id
   */
  GUIContextServiceDeleteGUIContextResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/guicontexts/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  GUIContextServiceDeleteGUIContext(id: number): __Observable<null> {
    return this.GUIContextServiceDeleteGUIContextResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.GUIContextServiceUpdateGUIContextParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `guicontext`:
   *
   * @return Ok
   */
  GUIContextServiceUpdateGUIContextResponse(params: ApiService.GUIContextServiceUpdateGUIContextParams): __Observable<__StrictHttpResponse<GUIContext>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.guicontext;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/guicontexts/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GUIContext>;
      })
    );
  }
  /**
   * @param params The `ApiService.GUIContextServiceUpdateGUIContextParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `guicontext`:
   *
   * @return Ok
   */
  GUIContextServiceUpdateGUIContext(params: ApiService.GUIContextServiceUpdateGUIContextParams): __Observable<GUIContext> {
    return this.GUIContextServiceUpdateGUIContextResponse(params).pipe(
      __map(_r => _r.body as GUIContext)
    );
  }

  /**
   * get all markerconfiguration
   * @param options
   * @return Ok
   */
  MarkerConfigurationServiceGetMarkerConfigurationsResponse(options: string): __Observable<__StrictHttpResponse<Array<MarkerConfiguration>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/markerconfigurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MarkerConfiguration>>;
      })
    );
  }
  /**
   * get all markerconfiguration
   * @param options
   * @return Ok
   */
  MarkerConfigurationServiceGetMarkerConfigurations(options: string): __Observable<Array<MarkerConfiguration>> {
    return this.MarkerConfigurationServiceGetMarkerConfigurationsResponse(options).pipe(
      __map(_r => _r.body as Array<MarkerConfiguration>)
    );
  }

  /**
   * @param markerconfiguration
   * @return Ok
   */
  MarkerConfigurationServiceAddMarkerConfigurationResponse(markerconfiguration: MarkerConfiguration): __Observable<__StrictHttpResponse<MarkerConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = markerconfiguration;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/markerconfigurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarkerConfiguration>;
      })
    );
  }
  /**
   * @param markerconfiguration
   * @return Ok
   */
  MarkerConfigurationServiceAddMarkerConfiguration(markerconfiguration: MarkerConfiguration): __Observable<MarkerConfiguration> {
    return this.MarkerConfigurationServiceAddMarkerConfigurationResponse(markerconfiguration).pipe(
      __map(_r => _r.body as MarkerConfiguration)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  MarkerConfigurationServiceGetMarkerConfigurationResponse(id: number): __Observable<__StrictHttpResponse<MarkerConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/markerconfigurations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarkerConfiguration>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  MarkerConfigurationServiceGetMarkerConfiguration(id: number): __Observable<MarkerConfiguration> {
    return this.MarkerConfigurationServiceGetMarkerConfigurationResponse(id).pipe(
      __map(_r => _r.body as MarkerConfiguration)
    );
  }

  /**
   * @param id
   */
  MarkerConfigurationServiceDeleteMarkerConfigurationResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/markerconfigurations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  MarkerConfigurationServiceDeleteMarkerConfiguration(id: number): __Observable<null> {
    return this.MarkerConfigurationServiceDeleteMarkerConfigurationResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.MarkerConfigurationServiceUpdateMarkerConfigurationParams` containing the following parameters:
   *
   * - `markerconfiguration`:
   *
   * - `id`:
   *
   * @return Ok
   */
  MarkerConfigurationServiceUpdateMarkerConfigurationResponse(params: ApiService.MarkerConfigurationServiceUpdateMarkerConfigurationParams): __Observable<__StrictHttpResponse<MarkerConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.markerconfiguration;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/markerconfigurations/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarkerConfiguration>;
      })
    );
  }
  /**
   * @param params The `ApiService.MarkerConfigurationServiceUpdateMarkerConfigurationParams` containing the following parameters:
   *
   * - `markerconfiguration`:
   *
   * - `id`:
   *
   * @return Ok
   */
  MarkerConfigurationServiceUpdateMarkerConfiguration(params: ApiService.MarkerConfigurationServiceUpdateMarkerConfigurationParams): __Observable<MarkerConfiguration> {
    return this.MarkerConfigurationServiceUpdateMarkerConfigurationResponse(params).pipe(
      __map(_r => _r.body as MarkerConfiguration)
    );
  }

  /**
   * get all place
   * @param options
   * @return Ok
   */
  PlaceServiceGetPlacesResponse(options: string): __Observable<__StrictHttpResponse<Array<Place>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/places`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Place>>;
      })
    );
  }
  /**
   * get all place
   * @param options
   * @return Ok
   */
  PlaceServiceGetPlaces(options: string): __Observable<Array<Place>> {
    return this.PlaceServiceGetPlacesResponse(options).pipe(
      __map(_r => _r.body as Array<Place>)
    );
  }

  /**
   * @param place
   * @return Ok
   */
  PlaceServiceAddPlaceResponse(place: Place): __Observable<__StrictHttpResponse<Place>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = place;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/places`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Place>;
      })
    );
  }
  /**
   * @param place
   * @return Ok
   */
  PlaceServiceAddPlace(place: Place): __Observable<Place> {
    return this.PlaceServiceAddPlaceResponse(place).pipe(
      __map(_r => _r.body as Place)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  PlaceServiceGetPlaceResponse(id: number): __Observable<__StrictHttpResponse<Place>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/places/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Place>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  PlaceServiceGetPlace(id: number): __Observable<Place> {
    return this.PlaceServiceGetPlaceResponse(id).pipe(
      __map(_r => _r.body as Place)
    );
  }

  /**
   * @param id
   */
  PlaceServiceDeletePlaceResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/places/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  PlaceServiceDeletePlace(id: number): __Observable<null> {
    return this.PlaceServiceDeletePlaceResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.PlaceServiceUpdatePlaceParams` containing the following parameters:
   *
   * - `place`:
   *
   * - `id`:
   *
   * @return Ok
   */
  PlaceServiceUpdatePlaceResponse(params: ApiService.PlaceServiceUpdatePlaceParams): __Observable<__StrictHttpResponse<Place>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.place;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/places/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Place>;
      })
    );
  }
  /**
   * @param params The `ApiService.PlaceServiceUpdatePlaceParams` containing the following parameters:
   *
   * - `place`:
   *
   * - `id`:
   *
   * @return Ok
   */
  PlaceServiceUpdatePlace(params: ApiService.PlaceServiceUpdatePlaceParams): __Observable<Place> {
    return this.PlaceServiceUpdatePlaceResponse(params).pipe(
      __map(_r => _r.body as Place)
    );
  }

  /**
   * get all plannedlayout
   * @param options
   * @return Ok
   */
  PlannedLayoutServiceGetPlannedLayoutsResponse(options: string): __Observable<__StrictHttpResponse<Array<PlannedLayout>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/plannedlayouts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PlannedLayout>>;
      })
    );
  }
  /**
   * get all plannedlayout
   * @param options
   * @return Ok
   */
  PlannedLayoutServiceGetPlannedLayouts(options: string): __Observable<Array<PlannedLayout>> {
    return this.PlannedLayoutServiceGetPlannedLayoutsResponse(options).pipe(
      __map(_r => _r.body as Array<PlannedLayout>)
    );
  }

  /**
   * @param plannedlayout
   * @return Ok
   */
  PlannedLayoutServiceAddPlannedLayoutResponse(plannedlayout: PlannedLayout): __Observable<__StrictHttpResponse<PlannedLayout>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = plannedlayout;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/plannedlayouts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PlannedLayout>;
      })
    );
  }
  /**
   * @param plannedlayout
   * @return Ok
   */
  PlannedLayoutServiceAddPlannedLayout(plannedlayout: PlannedLayout): __Observable<PlannedLayout> {
    return this.PlannedLayoutServiceAddPlannedLayoutResponse(plannedlayout).pipe(
      __map(_r => _r.body as PlannedLayout)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  PlannedLayoutServiceGetPlannedLayoutResponse(id: number): __Observable<__StrictHttpResponse<PlannedLayout>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/plannedlayouts/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PlannedLayout>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  PlannedLayoutServiceGetPlannedLayout(id: number): __Observable<PlannedLayout> {
    return this.PlannedLayoutServiceGetPlannedLayoutResponse(id).pipe(
      __map(_r => _r.body as PlannedLayout)
    );
  }

  /**
   * @param id
   */
  PlannedLayoutServiceDeletePlannedLayoutResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/plannedlayouts/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  PlannedLayoutServiceDeletePlannedLayout(id: number): __Observable<null> {
    return this.PlannedLayoutServiceDeletePlannedLayoutResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.PlannedLayoutServiceUpdatePlannedLayoutParams` containing the following parameters:
   *
   * - `plannedlayout`:
   *
   * - `id`:
   *
   * @return Ok
   */
  PlannedLayoutServiceUpdatePlannedLayoutResponse(params: ApiService.PlannedLayoutServiceUpdatePlannedLayoutParams): __Observable<__StrictHttpResponse<PlannedLayout>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.plannedlayout;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/plannedlayouts/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PlannedLayout>;
      })
    );
  }
  /**
   * @param params The `ApiService.PlannedLayoutServiceUpdatePlannedLayoutParams` containing the following parameters:
   *
   * - `plannedlayout`:
   *
   * - `id`:
   *
   * @return Ok
   */
  PlannedLayoutServiceUpdatePlannedLayout(params: ApiService.PlannedLayoutServiceUpdatePlannedLayoutParams): __Observable<PlannedLayout> {
    return this.PlannedLayoutServiceUpdatePlannedLayoutResponse(params).pipe(
      __map(_r => _r.body as PlannedLayout)
    );
  }

  /**
   * get all referencetransform
   * @param options
   * @return Ok
   */
  ReferenceTransformServiceGetReferenceTransformsResponse(options: string): __Observable<__StrictHttpResponse<Array<ReferenceTransform>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/referencetransforms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReferenceTransform>>;
      })
    );
  }
  /**
   * get all referencetransform
   * @param options
   * @return Ok
   */
  ReferenceTransformServiceGetReferenceTransforms(options: string): __Observable<Array<ReferenceTransform>> {
    return this.ReferenceTransformServiceGetReferenceTransformsResponse(options).pipe(
      __map(_r => _r.body as Array<ReferenceTransform>)
    );
  }

  /**
   * @param referencetransform
   * @return Ok
   */
  ReferenceTransformServiceAddReferenceTransformResponse(referencetransform: ReferenceTransform): __Observable<__StrictHttpResponse<ReferenceTransform>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = referencetransform;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/referencetransforms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferenceTransform>;
      })
    );
  }
  /**
   * @param referencetransform
   * @return Ok
   */
  ReferenceTransformServiceAddReferenceTransform(referencetransform: ReferenceTransform): __Observable<ReferenceTransform> {
    return this.ReferenceTransformServiceAddReferenceTransformResponse(referencetransform).pipe(
      __map(_r => _r.body as ReferenceTransform)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  ReferenceTransformServiceGetReferenceTransformResponse(id: number): __Observable<__StrictHttpResponse<ReferenceTransform>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/referencetransforms/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferenceTransform>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  ReferenceTransformServiceGetReferenceTransform(id: number): __Observable<ReferenceTransform> {
    return this.ReferenceTransformServiceGetReferenceTransformResponse(id).pipe(
      __map(_r => _r.body as ReferenceTransform)
    );
  }

  /**
   * @param id
   */
  ReferenceTransformServiceDeleteReferenceTransformResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/referencetransforms/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  ReferenceTransformServiceDeleteReferenceTransform(id: number): __Observable<null> {
    return this.ReferenceTransformServiceDeleteReferenceTransformResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.ReferenceTransformServiceUpdateReferenceTransformParams` containing the following parameters:
   *
   * - `referencetransform`:
   *
   * - `id`:
   *
   * @return Ok
   */
  ReferenceTransformServiceUpdateReferenceTransformResponse(params: ApiService.ReferenceTransformServiceUpdateReferenceTransformParams): __Observable<__StrictHttpResponse<ReferenceTransform>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.referencetransform;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/referencetransforms/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferenceTransform>;
      })
    );
  }
  /**
   * @param params The `ApiService.ReferenceTransformServiceUpdateReferenceTransformParams` containing the following parameters:
   *
   * - `referencetransform`:
   *
   * - `id`:
   *
   * @return Ok
   */
  ReferenceTransformServiceUpdateReferenceTransform(params: ApiService.ReferenceTransformServiceUpdateReferenceTransformParams): __Observable<ReferenceTransform> {
    return this.ReferenceTransformServiceUpdateReferenceTransformResponse(params).pipe(
      __map(_r => _r.body as ReferenceTransform)
    );
  }

  /**
   * get all role
   * @param options
   * @return Ok
   */
  RoleServiceGetRolesResponse(options: string): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * get all role
   * @param options
   * @return Ok
   */
  RoleServiceGetRoles(options: string): __Observable<Array<Role>> {
    return this.RoleServiceGetRolesResponse(options).pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }

  /**
   * @param role
   * @return Ok
   */
  RoleServiceAddRoleResponse(role: Role): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = role;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * @param role
   * @return Ok
   */
  RoleServiceAddRole(role: Role): __Observable<Role> {
    return this.RoleServiceAddRoleResponse(role).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  RoleServiceGetRoleResponse(id: number): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/roles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  RoleServiceGetRole(id: number): __Observable<Role> {
    return this.RoleServiceGetRoleResponse(id).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * @param id
   */
  RoleServiceDeleteRoleResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/roles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  RoleServiceDeleteRole(id: number): __Observable<null> {
    return this.RoleServiceDeleteRoleResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.RoleServiceUpdateRoleParams` containing the following parameters:
   *
   * - `role`:
   *
   * - `id`:
   *
   * @return Ok
   */
  RoleServiceUpdateRoleResponse(params: ApiService.RoleServiceUpdateRoleParams): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.role;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/roles/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * @param params The `ApiService.RoleServiceUpdateRoleParams` containing the following parameters:
   *
   * - `role`:
   *
   * - `id`:
   *
   * @return Ok
   */
  RoleServiceUpdateRole(params: ApiService.RoleServiceUpdateRoleParams): __Observable<Role> {
    return this.RoleServiceUpdateRoleResponse(params).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * get all site
   * @param options
   * @return Ok
   */
  SiteServiceGetSitesResponse(options: string): __Observable<__StrictHttpResponse<Array<Site>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Site>>;
      })
    );
  }
  /**
   * get all site
   * @param options
   * @return Ok
   */
  SiteServiceGetSites(options: string): __Observable<Array<Site>> {
    return this.SiteServiceGetSitesResponse(options).pipe(
      __map(_r => _r.body as Array<Site>)
    );
  }

  /**
   * @param site
   * @return Ok
   */
  SiteServiceAddSiteResponse(site: Site): __Observable<__StrictHttpResponse<Site>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = site;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Site>;
      })
    );
  }
  /**
   * @param site
   * @return Ok
   */
  SiteServiceAddSite(site: Site): __Observable<Site> {
    return this.SiteServiceAddSiteResponse(site).pipe(
      __map(_r => _r.body as Site)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  SiteServiceGetSiteResponse(id: number): __Observable<__StrictHttpResponse<Site>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sites/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Site>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  SiteServiceGetSite(id: number): __Observable<Site> {
    return this.SiteServiceGetSiteResponse(id).pipe(
      __map(_r => _r.body as Site)
    );
  }

  /**
   * @param id
   */
  SiteServiceDeleteSiteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sites/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  SiteServiceDeleteSite(id: number): __Observable<null> {
    return this.SiteServiceDeleteSiteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.SiteServiceUpdateSiteParams` containing the following parameters:
   *
   * - `site`:
   *
   * - `id`:
   *
   * @return Ok
   */
  SiteServiceUpdateSiteResponse(params: ApiService.SiteServiceUpdateSiteParams): __Observable<__StrictHttpResponse<Site>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.site;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sites/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Site>;
      })
    );
  }
  /**
   * @param params The `ApiService.SiteServiceUpdateSiteParams` containing the following parameters:
   *
   * - `site`:
   *
   * - `id`:
   *
   * @return Ok
   */
  SiteServiceUpdateSite(params: ApiService.SiteServiceUpdateSiteParams): __Observable<Site> {
    return this.SiteServiceUpdateSiteResponse(params).pipe(
      __map(_r => _r.body as Site)
    );
  }

  /**
   * get all stocktake
   * @param options
   * @return Ok
   */
  StocktakeServiceGetStocktakesResponse(options: string): __Observable<__StrictHttpResponse<Array<Stocktake>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stocktakes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Stocktake>>;
      })
    );
  }
  /**
   * get all stocktake
   * @param options
   * @return Ok
   */
  StocktakeServiceGetStocktakes(options: string): __Observable<Array<Stocktake>> {
    return this.StocktakeServiceGetStocktakesResponse(options).pipe(
      __map(_r => _r.body as Array<Stocktake>)
    );
  }

  /**
   * @param stocktake
   * @return Ok
   */
  StocktakeServiceAddStocktakeResponse(stocktake: Stocktake): __Observable<__StrictHttpResponse<Stocktake>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = stocktake;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stocktakes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Stocktake>;
      })
    );
  }

  StocktakeColaApiResponse(stocktake: any): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = stocktake;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stocktakes/colaApi`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Stocktake>;
      })
    );
  }
  /**
   * @param stocktake
   * @return Ok
   */
  StocktakeServiceAddStocktake(stocktake: Stocktake): __Observable<Stocktake> {
    return this.StocktakeServiceAddStocktakeResponse(stocktake).pipe(
      __map(_r => _r.body as Stocktake)
    );
  }

  StocktakeColaApi(data: any): __Observable<any> {
    return this.StocktakeColaApiResponse(data).pipe(
      __map(_r => _r.body)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  StocktakeServiceGetStocktakeResponse(id: number): __Observable<__StrictHttpResponse<Stocktake>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stocktakes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Stocktake>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  StocktakeServiceGetStocktake(id: number): __Observable<Stocktake> {
    return this.StocktakeServiceGetStocktakeResponse(id).pipe(
      __map(_r => _r.body as Stocktake)
    );
  }

  /**
   * @param id
   */
  StocktakeServiceDeleteStocktakeResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/stocktakes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  StocktakeServiceDeleteStocktake(id: number): __Observable<null> {
    return this.StocktakeServiceDeleteStocktakeResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.StocktakeServiceUpdateStocktakeParams` containing the following parameters:
   *
   * - `stocktake`:
   *
   * - `id`:
   *
   * @return Ok
   */
  StocktakeServiceUpdateStocktakeResponse(params: ApiService.StocktakeServiceUpdateStocktakeParams): __Observable<__StrictHttpResponse<Stocktake>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.stocktake;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/stocktakes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Stocktake>;
      })
    );
  }
  /**
   * @param params The `ApiService.StocktakeServiceUpdateStocktakeParams` containing the following parameters:
   *
   * - `stocktake`:
   *
   * - `id`:
   *
   * @return Ok
   */
  StocktakeServiceUpdateStocktake(params: ApiService.StocktakeServiceUpdateStocktakeParams): __Observable<Stocktake> {
    return this.StocktakeServiceUpdateStocktakeResponse(params).pipe(
      __map(_r => _r.body as Stocktake)
    );
  }

  /**
   * get all stocktaketoarea
   * @param options
   * @return Ok
   */
  StocktakeToAreaServiceGetStocktakeToAreasResponse(options: string): __Observable<__StrictHttpResponse<Array<StocktakeToArea>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stocktaketoareas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StocktakeToArea>>;
      })
    );
  }
  /**
   * get all stocktaketoarea
   * @param options
   * @return Ok
   */
  StocktakeToAreaServiceGetStocktakeToAreas(options: string): __Observable<Array<StocktakeToArea>> {
    return this.StocktakeToAreaServiceGetStocktakeToAreasResponse(options).pipe(
      __map(_r => _r.body as Array<StocktakeToArea>)
    );
  }

  /**
   * @param stocktaketoarea
   * @return Ok
   */
  StocktakeToAreaServiceAddStocktakeToAreaResponse(stocktaketoarea: StocktakeToArea): __Observable<__StrictHttpResponse<StocktakeToArea>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = stocktaketoarea;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stocktaketoareas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StocktakeToArea>;
      })
    );
  }
  /**
   * @param stocktaketoarea
   * @return Ok
   */
  StocktakeToAreaServiceAddStocktakeToArea(stocktaketoarea: StocktakeToArea): __Observable<StocktakeToArea> {
    return this.StocktakeToAreaServiceAddStocktakeToAreaResponse(stocktaketoarea).pipe(
      __map(_r => _r.body as StocktakeToArea)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  StocktakeToAreaServiceGetStocktakeToAreaResponse(id: number): __Observable<__StrictHttpResponse<StocktakeToArea>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stocktaketoareas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StocktakeToArea>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  StocktakeToAreaServiceGetStocktakeToArea(id: number): __Observable<StocktakeToArea> {
    return this.StocktakeToAreaServiceGetStocktakeToAreaResponse(id).pipe(
      __map(_r => _r.body as StocktakeToArea)
    );
  }

  /**
   * @param id
   */
  StocktakeToAreaServiceDeleteStocktakeToAreaResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/stocktaketoareas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  StocktakeToAreaServiceDeleteStocktakeToArea(id: number): __Observable<null> {
    return this.StocktakeToAreaServiceDeleteStocktakeToAreaResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.StocktakeToAreaServiceUpdateStocktakeToAreaParams` containing the following parameters:
   *
   * - `stocktaketoarea`:
   *
   * - `id`:
   *
   * @return Ok
   */
  StocktakeToAreaServiceUpdateStocktakeToAreaResponse(params: ApiService.StocktakeToAreaServiceUpdateStocktakeToAreaParams): __Observable<__StrictHttpResponse<StocktakeToArea>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.stocktaketoarea;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/stocktaketoareas/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StocktakeToArea>;
      })
    );
  }
  /**
   * @param params The `ApiService.StocktakeToAreaServiceUpdateStocktakeToAreaParams` containing the following parameters:
   *
   * - `stocktaketoarea`:
   *
   * - `id`:
   *
   * @return Ok
   */
  StocktakeToAreaServiceUpdateStocktakeToArea(params: ApiService.StocktakeToAreaServiceUpdateStocktakeToAreaParams): __Observable<StocktakeToArea> {
    return this.StocktakeToAreaServiceUpdateStocktakeToAreaResponse(params).pipe(
      __map(_r => _r.body as StocktakeToArea)
    );
  }

  /**
   * get all user
   * @param options
   * @return Ok
   */
  UserServiceGetUsersResponse(options: string): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * get all user
   * @param options
   * @return Ok
   */
  UserServiceGetUsers(options: string): __Observable<Array<User>> {
    return this.UserServiceGetUsersResponse(options).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param user
   * @return Ok
   */
  UserServiceAddUserResponse(user: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param user
   * @return Ok
   */
  UserServiceAddUser(user: User): __Observable<User> {
    return this.UserServiceAddUserResponse(user).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  UserServiceGetUserResponse(id: number): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  UserServiceGetUser(id: number): __Observable<User> {
    return this.UserServiceGetUserResponse(id).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param id
   */
  UserServiceDeleteUserResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  UserServiceDeleteUser(id: number): __Observable<null> {
    return this.UserServiceDeleteUserResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.UserServiceUpdateUserParams` containing the following parameters:
   *
   * - `user`:
   *
   * - `id`:
   *
   * @return Ok
   */
  UserServiceUpdateUserResponse(params: ApiService.UserServiceUpdateUserParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.user;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `ApiService.UserServiceUpdateUserParams` containing the following parameters:
   *
   * - `user`:
   *
   * - `id`:
   *
   * @return Ok
   */
  UserServiceUpdateUser(params: ApiService.UserServiceUpdateUserParams): __Observable<User> {
    return this.UserServiceUpdateUserResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * get all userauthdata
   * @param options
   * @return Ok
   */
  UserAuthDataServiceGetUserAuthDatasResponse(options: string): __Observable<__StrictHttpResponse<Array<UserAuthData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (options != null) __params = __params.set('options', options.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/userauthdatas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserAuthData>>;
      })
    );
  }
  /**
   * get all userauthdata
   * @param options
   * @return Ok
   */
  UserAuthDataServiceGetUserAuthDatas(options: string): __Observable<Array<UserAuthData>> {
    return this.UserAuthDataServiceGetUserAuthDatasResponse(options).pipe(
      __map(_r => _r.body as Array<UserAuthData>)
    );
  }

  /**
   * @param userauthdata
   * @return Ok
   */
  UserAuthDataServiceAddUserAuthDataResponse(userauthdata: UserAuthData): __Observable<__StrictHttpResponse<UserAuthData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userauthdata;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/userauthdatas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAuthData>;
      })
    );
  }
  /**
   * @param userauthdata
   * @return Ok
   */
  UserAuthDataServiceAddUserAuthData(userauthdata: UserAuthData): __Observable<UserAuthData> {
    return this.UserAuthDataServiceAddUserAuthDataResponse(userauthdata).pipe(
      __map(_r => _r.body as UserAuthData)
    );
  }

  /**
   * @param id
   * @return Ok
   */
  UserAuthDataServiceGetUserAuthDataResponse(id: number): __Observable<__StrictHttpResponse<UserAuthData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/userauthdatas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAuthData>;
      })
    );
  }
  /**
   * @param id
   * @return Ok
   */
  UserAuthDataServiceGetUserAuthData(id: number): __Observable<UserAuthData> {
    return this.UserAuthDataServiceGetUserAuthDataResponse(id).pipe(
      __map(_r => _r.body as UserAuthData)
    );
  }

  /**
   * @param id
   */
  UserAuthDataServiceDeleteUserAuthDataResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/userauthdatas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id
   */
  UserAuthDataServiceDeleteUserAuthData(id: number): __Observable<null> {
    return this.UserAuthDataServiceDeleteUserAuthDataResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ApiService.UserAuthDataServiceUpdateUserAuthDataParams` containing the following parameters:
   *
   * - `userauthdata`:
   *
   * - `id`:
   *
   * @return Ok
   */
  UserAuthDataServiceUpdateUserAuthDataResponse(params: ApiService.UserAuthDataServiceUpdateUserAuthDataParams): __Observable<__StrictHttpResponse<UserAuthData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.userauthdata;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/userauthdatas/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAuthData>;
      })
    );
  }
  /**
   * @param params The `ApiService.UserAuthDataServiceUpdateUserAuthDataParams` containing the following parameters:
   *
   * - `userauthdata`:
   *
   * - `id`:
   *
   * @return Ok
   */
  UserAuthDataServiceUpdateUserAuthData(params: ApiService.UserAuthDataServiceUpdateUserAuthDataParams): __Observable<UserAuthData> {
    return this.UserAuthDataServiceUpdateUserAuthDataResponse(params).pipe(
      __map(_r => _r.body as UserAuthData)
    );
  }
}

module ApiService {

  /**
   * Parameters for AreaServiceUpdateArea
   */
  export interface AreaServiceUpdateAreaParams {
    id: number;
    area: Area;
  }

  /**
   * Parameters for BoxServiceUpdateBox
   */
  export interface BoxServiceUpdateBoxParams {
    id: number;
    box: Box;
  }

  /**
   * Parameters for BoxElementServiceUpdateBoxElement
   */
  export interface BoxElementServiceUpdateBoxElementParams {
    id: number;
    boxelement: BoxElement;
  }

  /**
   * Parameters for FoundPositionServiceUpdateFoundPosition
   */
  export interface FoundPositionServiceUpdateFoundPositionParams {
    id: number;
    foundposition: FoundPosition;
  }

  /**
   * Parameters for GUIContextServiceUpdateGUIContext
   */
  export interface GUIContextServiceUpdateGUIContextParams {
    id: number;
    guicontext: GUIContext;
  }

  /**
   * Parameters for MarkerConfigurationServiceUpdateMarkerConfiguration
   */
  export interface MarkerConfigurationServiceUpdateMarkerConfigurationParams {
    markerconfiguration: MarkerConfiguration;
    id: number;
  }

  /**
   * Parameters for PlaceServiceUpdatePlace
   */
  export interface PlaceServiceUpdatePlaceParams {
    place: Place;
    id: number;
  }

  /**
   * Parameters for PlannedLayoutServiceUpdatePlannedLayout
   */
  export interface PlannedLayoutServiceUpdatePlannedLayoutParams {
    plannedlayout: {};
    id: number;
  }

  /**
   * Parameters for ReferenceTransformServiceUpdateReferenceTransform
   */
  export interface ReferenceTransformServiceUpdateReferenceTransformParams {
    referencetransform: ReferenceTransform;
    id: number;
  }

  /**
   * Parameters for RoleServiceUpdateRole
   */
  export interface RoleServiceUpdateRoleParams {
    role: Role;
    id: number;
  }

  /**
   * Parameters for SiteServiceUpdateSite
   */
  export interface SiteServiceUpdateSiteParams {
    site: Site;
    id: number;
  }

  /**
   * Parameters for StocktakeServiceUpdateStocktake
   */
  export interface StocktakeServiceUpdateStocktakeParams {
    stocktake: Stocktake;
    id: number;
  }

  /**
   * Parameters for StocktakeToAreaServiceUpdateStocktakeToArea
   */
  export interface StocktakeToAreaServiceUpdateStocktakeToAreaParams {
    stocktaketoarea: StocktakeToArea;
    id: number;
  }

  /**
   * Parameters for UserServiceUpdateUser
   */
  export interface UserServiceUpdateUserParams {
    user: User;
    id: number;
  }

  /**
   * Parameters for UserAuthDataServiceUpdateUserAuthData
   */
  export interface UserAuthDataServiceUpdateUserAuthDataParams {
    userauthdata: UserAuthData;
    id: number;
  }
}

export { ApiService }
