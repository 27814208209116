<div class="modal-content">

  <div class="modal-header">
    <h4 class="modal-title">{{modalHeader}}</h4>
    <button class="close" aria-label="Close" (click)="closeModal(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    {{modalContent}}
  </div>

  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-danger confirm-btn left" (click)="closeModal(false)">Nein</button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary confirm-btn right" (click)="closeModal(true)">Ja</button>
      </div>
    </div>
  </div>

</div>
