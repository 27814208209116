import { Component, ElementRef, HostListener } from '@angular/core';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { GlobalState } from '../../../../global.state';
import { RoleEnum } from '../../../interfaces/roles';
import { UserService } from '../../../services/user.service';
import { layoutSizes } from 'app/theme';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class sidebar {
  private subs: SubSink = new SubSink();
  public menuHeight: number;
  public isUserAdmin: boolean | undefined;
  public active_item: number = 2;
  public isWizardActive: boolean = false;
  public isMenuCollapsed: boolean = false;
  public isMenuDisabled: boolean = false;
  public isMenuShouldCollapsed: boolean = false;

  constructor(private _elementRef: ElementRef, private _state: GlobalState, private userService: UserService) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

    this._state.subscribe('menu.item', (menuItem) => {
      this.active_item = menuItem;
    });

    this._state.subscribe('menu.disable', (disabled) => {
      this.isMenuDisabled = disabled;
    });

    this._state.subscribe('wizard.active', (isActive) => {
      this.isWizardActive = isActive;
      if (isActive) this.active_item = 3;
    });
  }

  public ngOnInit(): void {
    this.subs.sink = this.userService._currentuser.pipe(filter((user) => !!user)).subscribe((user) => {
      this.isUserAdmin = user.roles && user.roles.length ? user.roles.includes(RoleEnum.Admin) : false;
    });
    if (this._shouldMenuCollapse()) {
      this.menuCollapse();
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.updateSidebarHeight());
  }

  public isActive(n: number) {
    return n == this.active_item;
  }

  public setActive(n: number) {
    if (!this.isMenuDisabled) this.active_item = n;
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    var isMenuShouldCollapsed = this._shouldMenuCollapse();

    if (this.isMenuShouldCollapsed !== isMenuShouldCollapsed) {
      this.menuCollapseStateChange(isMenuShouldCollapsed);
    }
    this.isMenuCollapsed
    this.isMenuShouldCollapsed = isMenuShouldCollapsed;
    this.updateSidebarHeight();
  }

  public menuExpand(): void {
    this.menuCollapseStateChange(false);
  }

  public menuCollapse(): void {
    this.menuCollapseStateChange(true);
  }

  public menuCollapseStateChange(isCollapsed: boolean): void {
    this.isMenuCollapsed = isCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
  }

  public updateSidebarHeight(): void {
    // TODO: get rid of magic 84 constant
    this.menuHeight = this._elementRef.nativeElement.childNodes[0].clientHeight - 84;
  }

  private _shouldMenuCollapse(): boolean {
    return window.innerWidth <= layoutSizes.resWidthCollapseSidebar;
  }

  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }
}
