import { Component, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AuthData, UserData, AuthService } from '../../../../authentication/auth.service';
import { SnackBarService, InfoType } from '../../../services/snack-bar.service';

import { UserService } from '../../../services/user.service';

import { User } from '../../../../api/models/user';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class Login {
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'Enter' && this.authForm.valid) {
      this.login();
    }
  }

  private subs: SubSink = new SubSink();
  public authForm: FormGroup;
  public inProgress: boolean;
  public returnUrl: string;
  public user: User;
  public users: Array<User>;
  public showerror: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.authForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/pages/stock';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * check for password and organize routing
   */
  public login() {
    this.inProgress = true;
    this.subs.sink = this.authService
      .authUser(this.authForm.value)
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe(
        (authData: AuthData) => {
          const currentUser: UserData = {
            id: authData.user.id,
            email: authData.user.email,
            language: authData.user.language,
            name: authData.user.name,
            roles: authData.user.roles,
          };
          this.userService.setCurrentUser(currentUser);
          this.authService.setTokenData(authData.token);
          this.router.navigateByUrl(this.returnUrl);
        },
        () => {
          this.snackBarService.showBar(4, 'Passwort oder Login ist nicht korrekt', '', InfoType.error);
        },
      );
  }
}
