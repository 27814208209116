import * as tslib_1 from "tslib";
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextService } from 'app/doks/services';
import { WizardService } from 'app/pages/operator/wizard';
import { StocktakeService } from "../../../services/stocktake.service";
var stocksList = /** @class */ (function () {
    function stocksList(stocktakeService, contextService, wizardService, router, route) {
        this.stocktakeService = stocktakeService;
        this.contextService = contextService;
        this.wizardService = wizardService;
        this.router = router;
        this.route = route;
        this.stocktakes = new Array();
        this.displayedColumns = ['id', "flightdate_start", "flightdate_stop", "status", "options"];
        this.dataSource = new MatTableDataSource();
        this.getStocktakes();
    }
    Object.defineProperty(stocksList.prototype, "editable", {
        get: function () { return this._editable; },
        set: function (b) {
            this._editable = b;
        },
        enumerable: true,
        configurable: true
    });
    stocksList.prototype.getStocktakes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.stocktakeService.getStocktakes()];
                    case 1: return [4 /*yield*/, (_b.sent()).reverse()];
                    case 2:
                        _a.stocktakes = _b.sent();
                        this.dataSource = new MatTableDataSource(this.stocktakes);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                        return [2 /*return*/];
                }
            });
        });
    };
    stocksList.prototype.gotostock = function (stocktake) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.wizardService;
                        return [4 /*yield*/, this.stocktakeService.getStocktake(stocktake.id)];
                    case 1:
                        _a.stocktake = _b.sent();
                        this.router.navigate(['/pages/stock/add'], { relativeTo: this.route });
                        return [2 /*return*/];
                }
            });
        });
    };
    stocksList.prototype.gotooverview = function (stocktake) {
        this.contextService.area = stocktake.stocktaketoareas[0].area;
        var context = this.contextService.context;
        context.areaId = this.contextService.area.id;
        context.stocktakeId = stocktake.id;
        this.contextService.context = context;
        this.router.navigate(['/pages/stock/overview'], { relativeTo: this.route });
    };
    stocksList.prototype.ngOnDestroy = function () {
        //this.sub.unsubscribe();
    };
    stocksList.prototype.applyFilter = function (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    return stocksList;
}());
export { stocksList };
