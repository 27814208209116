import { Component } from '@angular/core';

import { GlobalState } from '../../../../../global.state';

@Component({
  selector: 'backend-indicator',
  templateUrl: './backendIndicator.component.html',
  styleUrls: ['./backendIndicator.component.scss']
})
export class backendIndicator {

  public network_level: number = 0;
  public show_network_type: number = 0;
  public network_text: string = "backend not connected";

  constructor(private _state: GlobalState) {
    this._state.subscribe("websocket.connected", connected => {
      if (!connected)
        this.network_level = 0;
      else
        this.network_level = 1;

      this.calc_levels();
    });

    this.calc_levels();
  }


  calc_levels() {
    if (this.network_level > 0)
      this.network_text = "verbunden";
    else
      this.network_text = "DB nicht verbunden";

    if (this.network_level == 1)
      this.show_network_type = 1;
    else if (this.network_level == 2)
      this.show_network_type = 2;
    else
      this.show_network_type = 0;
  }
}
