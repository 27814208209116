import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { SnackBarService, InfoType } from '../doks/services/snack-bar.service';
import { UserService } from '../doks/services/user.service';

export interface AuthData {
  token: TokenData;
  user: UserData;
}

export interface TokenData {
  token: string;
  refreshToken: string;
}

export interface UserData {
  id?: number;
  password?: string;
  email?: string;
  language?: string;
  roles: string[];
  name: string;
}

@Injectable()
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private snackBarService: SnackBarService,
    private userService: UserService,
  ) {}

  public authUser(userData) {
    return this.http.post('api/auth', userData);
  }

  public refreshToken(): Observable<TokenData> {
    return this.http.post<TokenData>('api/auth/refreshToken', this.getTokenData());
  }

  public setTokenData(tokenData: TokenData) {
    window.localStorage.setItem('tokenData', JSON.stringify(tokenData));
  }

  public getTokenData() {
    return JSON.parse(window.localStorage.getItem('tokenData'));
  }

  public getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode<any>(token);
    if (!decoded.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  public isTokenExpired(): boolean {
    const tokenData = this.getTokenData();
    if (!tokenData || !tokenData.refreshToken) {
      return true;
    }
    const refreshToken = tokenData.refreshToken;
    const date = this.getTokenExpirationDate(refreshToken);
    if (!date) {
      return true;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  public resetAuthData(): void {
    window.localStorage.clear();
    this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    this.snackBarService.showBar(4, 'Sitzung beendet', '', InfoType.info);
  }
}
