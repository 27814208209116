<div class="table-wrapper">
  <table mat-table
         *ngIf="dataSource?.data?.length"
         [dataSource]="dataSource"
         matSort
         matSortDirection="asc"
         matSortDisableClear>

    <ng-container *ngFor="let columnName of displayedColumns">
      <ng-container [matColumnDef]="columnName.key">
        <ng-container *ngIf="columnName.key !== 'actions' &&  columnName.key !== 'details'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnName?.header }}</th>
          <td mat-cell *matCellDef="let element">
          <ng-container>
            {{columnName?.dateFormat ? (element.timestamp ? element.timestamp * 1000 : element.created_at | date:columnName?.dateFormat )  : element[columnName.key]}}
          </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="columnName.key === 'actions' ">
          <th mat-header-cell *matHeaderCellDef ></th>
          <td mat-cell *matCellDef="let row" class="align-right">
            <button mat-button *ngIf="edit.observers.length > 0 " (click)="editElement(row)" class="btn btn-primary confirm-btn">Bearbeiten</button>
            <button mat-button *ngIf="delete.observers.length > 0 && (!userIdToCheck || userIdToCheck !== row?.id)" (click)="deleteElement(row)" class="btn btn-danger confirm-btn ml-1">Löschen</button>
          </td>
        </ng-container>
        <ng-container *ngIf="columnName.key === 'details' ">
          <th mat-header-cell *matHeaderCellDef ></th>
          <td mat-cell *matCellDef="let row" class="align-right">
            <button mat-button (click)="goToDetails(row)" class="button confirm-btn w-full">Einzelheiten</button>
          </td>
        </ng-container>

      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsKeys"></tr>
  </table>
  <div class="no-data" *ngIf="!dataSource?.data?.length">Keine Daten gefunden</div>
</div>

