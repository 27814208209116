  <div class="dashboard-page">
    <div class="flex direction-column align-end">
      <button class="btn btn-primary confirm-btn" (click)="addUser()">Neuen Benutzer hinzufügen</button>
    </div>
    <gate-table [dataSource]="dataSource"
                 [displayedColumns]="displayedColumns"
                 [userIdToCheck]="currentUser.id"
                 (edit)="editUser($event)"
                 (delete)="deleteUser($event)">
    </gate-table>
  </div>
