import { Component, Input } from '@angular/core';
import { ContextService } from '../../../services/context.service';
import { SiteService } from '../../../services/site.service';
import { AreaService } from '../../../services/area.service';

import { Site } from '../../../../api/models/site';

@Component({
  selector: 'siteselector',
  templateUrl: './siteselector.component.html',
  styleUrls: ['./siteselector.component.scss'],
})
export class siteSelector {

  public buttontext: string = 'Kein Bereich vorhanden.';
  public selected: Site;
  public sites: Site[] = [];
  private sub: any;

  @Input()
  disabled: boolean = false;

  constructor(private contextService: ContextService,
    private siteService: SiteService,
    private areaService: AreaService) {
  }

  ngOnInit() {
    this.sub = this.siteService.sites.subscribe(
      sites => {
        if (sites.length == 0) {
          this.buttontext = 'Kein Bereich vorhanden.';
          return;
        }

        this.sites = sites;
        this.buttontext = 'Wähle Bereich';

        const ctx = this.contextService.context;
        if (ctx) {
          const site = this.siteService.getSite(ctx.siteId);
          this.select(site);
        }
      });
    // load available sites
    this.siteService.loadSites();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
  }

  select(s: Site) {
    this.selected = s;
    this.buttontext = '';
    this.contextService.site = this.selected;
    this.areaService.loadAreas();
  }

}
