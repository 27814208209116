export const url = 'cocacolazurich.doks-summairy.com';

export const environment = {
  production: true,
  useGUICloud: true,
  version: '1.2.0',
  licenseKey: 'dc0afde1-5b73-4c8f-a16f-6a253a74ffed',
  language: 'de',
  locationId: 1,
  backgroundImage: '/assets/img/warehouse.jpg',
  apiURL: 'https://' + url + '/api',
  wsURL: 'wss://' + url + '/api/',
  hostname: 'https://' + url,
  //  hostname:           "",
  orthoPath: '/ortho_dem/ortho_medium.jpg',
  cloudPath: '/home/ubuntu/flights',
  osm: {
    centermap: {
      lat: 47.814348,
      lng: 12.613291,
    },
  },
  openweather: {
    baseUrl: 'http://api.openweathermap.org/data/2.5/',
    appId: '70540ebedcac85cce1da9540417e0f33',
    units: 'metric',
    maxWind: 12,
  },
  ml_defaults: {
    theta_lo: -1.0,
    theta_hi: 1.0,
  },
};
