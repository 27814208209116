/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pageTop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../currentLocation/currentLocation.component.ngfactory";
import * as i4 from "../currentLocation/currentLocation.component";
import * as i5 from "../../../../../global.state";
import * as i6 from "../../../../services/context.service";
import * as i7 from "@angular/router";
import * as i8 from "../reloadWindow/reloadWindow.component.ngfactory";
import * as i9 from "../reloadWindow/reloadWindow.component";
import * as i10 from "../backendIndicator/backendIndicator.component.ngfactory";
import * as i11 from "../backendIndicator/backendIndicator.component";
import * as i12 from "../gpuIndicator/gpuIndicator.component.ngfactory";
import * as i13 from "../gpuIndicator/gpuIndicator.component";
import * as i14 from "../../../../services/backend.service";
import * as i15 from "./pageTop.component";
import * as i16 from "../../../../../authentication/auth.service";
var styles_pageTop = [i0.styles];
var RenderType_pageTop = i1.ɵcrt({ encapsulation: 0, styles: styles_pageTop, data: {} });
export { RenderType_pageTop as RenderType_pageTop };
export function View_pageTop_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 43, "div", [["class", "page-top clearfix"], ["maxHeight", "50"]], null, [[null, "scrollChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrollChange" === en)) {
        var pd_0 = (_co.scrolledChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { scrolled: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "location_title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "current-location", [], null, null, null, i3.View_currentLocation_0, i3.RenderType_currentLocation)), i1.ɵdid(6, 49152, null, 0, i4.currentLocation, [i5.GlobalState, i6.ContextService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 36, "div", [["class", "icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "menuitem-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "a", [["href", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-sign-out"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "textlevel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Logout "])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "div", [["class", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 6, "a", [["translate", ""]], [[2, "blockcursor", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "menuitem-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fa fa-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "textlevel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Profil "])), (_l()(), i1.ɵeld(23, 0, null, null, 7, "div", [["class", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 6, "a", [["translate", ""]], [[2, "blockcursor", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["class", "menuitem-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, "i", [["class", "fa fa-question-circle-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "div", [["class", "textlevel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hilfe "])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "reload-window", [], null, null, null, i8.View_reloadWindow_0, i8.RenderType_reloadWindow)), i1.ɵdid(32, 49152, null, 0, i9.reloadWindow, [], null, null), (_l()(), i1.ɵeld(33, 0, null, null, 6, "div", [["class", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 5, "div", [["class", "menuitem-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 4, "a", [["href", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 0, "i", [["class", "fa fa-trash-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 1, "div", [["class", "textlevel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cleanworkspace() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Stocktakes leeren "])), (_l()(), i1.ɵeld(40, 0, null, null, 1, "backend-indicator", [], null, null, null, i10.View_backendIndicator_0, i10.RenderType_backendIndicator)), i1.ɵdid(41, 49152, null, 0, i11.backendIndicator, [i5.GlobalState], null, null), (_l()(), i1.ɵeld(42, 0, null, null, 1, "gpu-indicator", [], null, null, null, i12.View_gpuIndicator_0, i12.RenderType_gpuIndicator)), i1.ɵdid(43, 49152, null, 0, i13.gpuIndicator, [i5.GlobalState, i14.BackendService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "page-top clearfix"; var currVal_1 = _ck(_v, 3, 0, _co.isScrolled); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_5 = (_co.isMenuDisabled ? null : "/pages/profile"); _ck(_v, 17, 0, currVal_5); var currVal_9 = (_co.isMenuDisabled ? null : "/pages/help"); _ck(_v, 25, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isMenuDisabled; var currVal_3 = i1.ɵnov(_v, 17).target; var currVal_4 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.isMenuDisabled; var currVal_7 = i1.ɵnov(_v, 25).target; var currVal_8 = i1.ɵnov(_v, 25).href; _ck(_v, 24, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_pageTop_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-top", [], null, null, null, View_pageTop_0, RenderType_pageTop)), i1.ɵdid(1, 49152, null, 0, i15.pageTop, [i7.Router, i7.ActivatedRoute, i14.BackendService, i16.AuthService, i5.GlobalState], null, null)], null, null); }
var pageTopNgFactory = i1.ɵccf("page-top", i15.pageTop, View_pageTop_Host_0, {}, {}, []);
export { pageTopNgFactory as pageTopNgFactory };
