/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../doks/components/layout/sidebar/sidebar.component.ngfactory";
import * as i2 from "../doks/components/layout/sidebar/sidebar.component";
import * as i3 from "../global.state";
import * as i4 from "../doks/services/user.service";
import * as i5 from "../doks/components/layout/header/pageTop/pageTop.component.ngfactory";
import * as i6 from "../doks/components/layout/header/pageTop/pageTop.component";
import * as i7 from "@angular/router";
import * as i8 from "../doks/services/backend.service";
import * as i9 from "../authentication/auth.service";
import * as i10 from "./pages.component";
import * as i11 from "../theme/services/baMenu/baMenu.service";
import * as i12 from "../doks/services/context.service";
var styles_Pages = [];
var RenderType_Pages = i0.ɵcrt({ encapsulation: 2, styles: styles_Pages, data: {} });
export { RenderType_Pages as RenderType_Pages };
export function View_Pages_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sidebar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_sidebar_0, i1.RenderType_sidebar)), i0.ɵdid(1, 4308992, null, 0, i2.sidebar, [i0.ElementRef, i3.GlobalState, i4.UserService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "page-top", [], null, null, null, i5.View_pageTop_0, i5.RenderType_pageTop)), i0.ɵdid(3, 49152, null, 0, i6.pageTop, [i7.Router, i7.ActivatedRoute, i8.BackendService, i9.AuthService, i3.GlobalState], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "al-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "al-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 7, 0); }, null); }
export function View_Pages_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pages", [], null, null, null, View_Pages_0, RenderType_Pages)), i0.ɵdid(1, 114688, null, 0, i10.Pages, [i11.BaMenuService, i12.ContextService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PagesNgFactory = i0.ɵccf("pages", i10.Pages, View_Pages_Host_0, {}, {}, []);
export { PagesNgFactory as PagesNgFactory };
