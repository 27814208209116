/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reloadWindow.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./reloadWindow.component";
var styles_reloadWindow = [i0.styles];
var RenderType_reloadWindow = i1.ɵcrt({ encapsulation: 0, styles: styles_reloadWindow, data: {} });
export { RenderType_reloadWindow as RenderType_reloadWindow };
export function View_reloadWindow_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "reloadbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "reloadbox-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-refresh"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "textlevel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Neu laden "]))], null, null); }
export function View_reloadWindow_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reload-window", [], null, null, null, View_reloadWindow_0, RenderType_reloadWindow)), i1.ɵdid(1, 49152, null, 0, i2.reloadWindow, [], null, null)], null, null); }
var reloadWindowNgFactory = i1.ɵccf("reload-window", i2.reloadWindow, View_reloadWindow_Host_0, {}, {}, []);
export { reloadWindowNgFactory as reloadWindowNgFactory };
