import { Component, OnInit, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormArray,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noop } from 'rxjs';
import { SubSink } from 'subsink';
import { UserData } from '../../../../authentication/auth.service';
import { RoleEnum } from '../../../../doks/interfaces/roles';
import { UserService } from '../../../../doks/services/user.service';

@Component({
  selector: 'service-user-management-dialog',
  templateUrl: './user-management-dialog.component.html',
  styleUrls: ['./user-management-dialog.component.scss'],
})
export class UserManagementDialogComponent implements OnInit {
  private subs: SubSink = new SubSink();
  public userDialogFrom: FormGroup;
  public matcher = new ErrorStateMatcher();
  public roles = Object.values(RoleEnum);

  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<UserManagementDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { user: UserData | null; users: UserData[] | null },
  ) {}

  ngOnInit(): void {
    this.userDialogFrom = this.formBuilder.group({
      email: new FormControl((this.data.user && this.data.user.email) || '', [
        Validators.required,
        Validators.email,
        this.alreadyExistsValidator(this.data.user && this.data.user.email, 'email'),
      ]),
      password: new FormControl(''),
      name: new FormControl((this.data.user && this.data.user.name) || '', [Validators.required]),
      roles: new FormArray([]),
    });
    this.initFormControls();
  }

  public initFormControls() {
    const roles = this.userDialogFrom.controls['roles'] as FormArray;
    if (this.data.user && this.data.user.roles && this.data.user.roles.length) {
      for (const role of this.data.user.roles) {
        roles.push(new FormControl(role));
      }
    }
    if (!this.data.user) {
      this.userDialogFrom.get('password')
        ? this.userDialogFrom.get('password').setValidators(Validators.required)
        : noop();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  alreadyExistsValidator(checkedInput: string | undefined | null, type: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.data && this.data.users) {
        for (const user of this.data.users) {
          if (user[type as keyof UserData] === control.value && checkedInput != control.value) {
            return { exist: { value: control.value } };
          }
        }
      }
      return null;
    };
  }

  setRole(event: any) {
    const selectedRoles = this.userDialogFrom.controls['roles'] as FormArray;
    if (event.checked) {
      selectedRoles.push(new FormControl(event.source.value));
    } else {
      const index = selectedRoles.controls.findIndex((x) => x.value === event.source.value);
      selectedRoles.removeAt(index);
    }
  }
}
