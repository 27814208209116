import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserManagementService = /** @class */ (function () {
    function UserManagementService(http) {
        this.http = http;
    }
    UserManagementService.prototype.getUsers = function () {
        return this.http.get("api/users");
    };
    UserManagementService.prototype.addUser = function (user) {
        return this.http.post("api/users", user);
    };
    UserManagementService.prototype.updateUser = function (user) {
        return this.http.put("api/users", user);
    };
    UserManagementService.prototype.deleteUser = function (id) {
        return this.http.delete("api/users/" + id);
    };
    UserManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.ɵɵinject(i1.HttpClient)); }, token: UserManagementService, providedIn: "root" });
    return UserManagementService;
}());
export { UserManagementService };
