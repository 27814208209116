import { Component, Input } from '@angular/core';
import { ContextService } from '../../../services/context.service';
import { AreaService } from '../../../services/area.service';

import { Area } from '../../../../api/models/area';

@Component({
  selector: 'areaselector',
  templateUrl: './areaselector.component.html',
  styleUrls: ['./areaselector.component.scss'],
})
export class areaSelector {

  public buttontext: string = 'Kein Leergutplatz vorhanden.';
  public selected: Area;
  public areas: Area[] = [];
  private sub: any;

  @Input()
  disabled: boolean = false;

  constructor(private contextService: ContextService,
    private areaService: AreaService) {
  }

  ngOnInit() {
    this.sub = this.areaService.areas.subscribe(
      areas => {
        if (areas.length == 0) {
          this.buttontext = 'Kein Leergutplatz vorhanden.';
          return;
        }

        this.areas = areas;
        this.buttontext = 'Wähle Leergutplatz';

        const ctx = this.contextService.context;
        if (ctx) {
          const area = this.areaService.getArea(ctx.areaId);
          if(area != undefined) {
            this.select(area);
          } else {
            this.select(this.areas[0])
          }
        } else {
          this.select(this.areas[0])
        }
      });
    // load available areas
    // this.areaService.loadAreas();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {}

  select(a: Area) {
    this.selected = a;
    this.buttontext = '';
    this.contextService.area = this.selected;
  }
}
