import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../../authentication/auth.service';
import { GlobalState } from '../../../../../global.state';
import { BackendService } from '../../../../services';

@Component({
  selector: 'page-top',
  templateUrl: './pageTop.component.html',
  styleUrls: ['./pageTop.component.scss'],
})
export class pageTop {
  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = false;
  public isMenuDisabled: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private backendService: BackendService,
    private authService: AuthService,
    private _state: GlobalState,
  ) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
    this._state.subscribe('menu.disable', (disabled) => {
      this.isMenuDisabled = disabled;
    });
  }

  public scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }

  /**
   * go to loginscreen
   */
  public logout() {
    this.authService.resetAuthData();
  }

  public cleanworkspace() {
    this.backendService.sendToWebsocketGetCleanWorkspace();
  }
}
