import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { AuthService, TokenData } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<TokenData | null> = new BehaviorSubject<TokenData | null>(null);

  constructor(private authService: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json'),
      });
    }

    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(req))),
            );
          } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshToken().pipe(
              switchMap((tokenData: TokenData) => {
                window.localStorage.setItem('tokenData', JSON.stringify(tokenData.token));
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(tokenData);
                return next.handle(this.addAuthenticationToken(req));
              }),
              catchError((err) => {
                this.authService.resetAuthData();
                this.refreshTokenInProgress = false;
                return throwError(err);
              }),
            );
          }
        } else {
          return throwError(error);
        }
      }),
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const tokenData = window.localStorage.getItem('tokenData');
    const accessToken = tokenData ? JSON.parse(tokenData).token : null;

    if (!accessToken) {
      return request;
    }
    const skip = request.headers.has('skip');
    if (skip) {
      request = request.clone({ headers: request.headers.delete('Content-Type', 'application/json') });
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + accessToken),
    });
  }
}
