<div class="page-top clearfix" maxHeight="50" (scrollChange)="scrolledChanged($event)"
     [ngClass]="{scrolled: isScrolled}">

  <div class="location_title">
    <current-location></current-location>
  </div>

<div class="icons">
<div class="menuitem">
    <div class="menuitem-inner">
      <a href="">
      <div class="icon"><i class="fa fa-sign-out"></i></div>
      <div class="textlevel" (click)="logout()">
        Logout
      </div>
    </a>
    </div>
  </div>

  <div class="menuitem">
    <a [routerLink]="isMenuDisabled ? null : '/pages/profile'" [class.blockcursor]="isMenuDisabled" translate>
      <div class="menuitem-inner">
        <div class="icon"><i class="fa fa-user"></i></div>
        <div class="textlevel">
          Profil
        </div>
      </div>
    </a>
  </div>

  <div class="menuitem">
    <a [routerLink]="isMenuDisabled ? null : '/pages/help'" [class.blockcursor]="isMenuDisabled" translate>
      <div class="menuitem-inner">
        <div class="icon"><i class="fa fa-question-circle-o"></i></div>
        <div class="textlevel">
          Hilfe
        </div>
      </div>
    </a>
  </div>

  <reload-window></reload-window>


  <div class="menuitem">
    <div class="menuitem-inner">
      <a href="">
        <div class="icon"><i class="fa fa-trash-o"></i></div>
        <div class="textlevel" (click)="cleanworkspace()">
          Stocktakes leeren
        </div>
      </a>
    </div>
  </div>

  <backend-indicator></backend-indicator>

  <gpu-indicator></gpu-indicator>
</div>

</div>
