import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { RoleEnum } from '../../doks/interfaces/roles';
import { UserService } from '../../doks/services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../doks/services/user.service";
var AdminGuard = /** @class */ (function () {
    function AdminGuard(router, userService) {
        this.router = router;
        this.userService = userService;
    }
    AdminGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.userService._currentuser.pipe(map(function (authenticated) {
            if (_this.userService.getCurrentUser()) {
                return _this.userService.getCurrentUser().roles.includes(RoleEnum.Admin);
            }
            return false;
        }));
    };
    AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.UserService)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
export { AdminGuard };
