import { Component } from '@angular/core';

@Component({
  selector: 'reload-window',
  templateUrl: './reloadWindow.component.html',
  styleUrls: ['./reloadWindow.component.scss'],
})
export class reloadWindow {
  constructor() {}

  public reloadWindow() {
    window.location.reload(true);
  }
}
