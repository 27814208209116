/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./theme/directives/baThemeRun/baThemeRun.directive";
import * as i3 from "./theme/theme.configProvider";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "./global.state";
import * as i7 from "./theme/services/baImageLoader/baImageLoader.service";
import * as i8 from "./theme/services/baThemeSpinner/baThemeSpinner.service";
import * as i9 from "./theme/theme.config";
import * as i10 from "./doks/services/context.service";
import * as i11 from "./doks/services/location.service";
import * as i12 from "./authentication/auth.service";
import * as i13 from "./doks/services/user.service";
var styles_App = [i0.styles];
var RenderType_App = i1.ɵcrt({ encapsulation: 0, styles: styles_App, data: {} });
export { RenderType_App as RenderType_App };
export function View_App_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "main", [["baThemeRun", ""]], [[2, "menu-collapsed", null], [8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.BaThemeRun, [i3.BaThemeConfigProvider], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "additional-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMenuCollapsed; var currVal_1 = i1.ɵnov(_v, 1).classesString; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_App_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app", [], null, null, null, View_App_0, RenderType_App)), i1.ɵdid(1, 245760, null, 0, i5.App, [i6.GlobalState, i7.BaImageLoaderService, i8.BaThemeSpinner, i1.ViewContainerRef, i9.BaThemeConfig, i10.ContextService, i11.LocationService, i4.Router, i12.AuthService, i13.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppNgFactory = i1.ɵccf("app", i5.App, View_App_Host_0, {}, {}, []);
export { AppNgFactory as AppNgFactory };
