export const PAGES_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: 'dashboard',
        data: {
          menu: {
            title: 'Dashboard',
            icon: 'ion-android-home',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'monitoring',
        data: {
          menu: {
            title: 'Monitoring',
            icon: 'ion-earth',
            selected: false,
            expanded: false,
            order: 100,
          }
        },
        children: [
          {
            path: 'overview',
            data: {
              menu: {
                title: 'Overview',
              }
            }
          },
          {
            path: 'locations2',
            data: {
              menu: {
                title: 'Warehouse #1',
              }
            }
          },
          {
            path: 'locations3',
            data: {
              menu: {
                title: 'Warehouse #2',
              }
            }
          }
        ]
      },
      {
        path: 'devices',
        data: {
          menu: {
            title: 'Devices',
            icon: 'ion-ios-pulse',
            selected: false,
            expanded: false,
            order: 100,
          }
        },
        children: [
          {
            path: 'dev/adam_one/1',
            data: {
              menu: {
                title: 'ADAMone',
              }
            }
          },
          {
            path: 'dev/adam_one/2',
            data: {
              menu: {
                title: 'drone-1',
              }
            }
          }
        ]
      },
      {
        path: 'activities',
        data: {
          menu: {
            title: 'Activities',
            icon: 'ion-android-walk',
            selected: false,
            expanded: false,
            order: 100,
          }
        },
        children: [
          {
            path: 'stocktake',
            data: {
              menu: {
                title: 'Stock Take',
              }
            }
          },
          {
            path: 'inspect',
            data: {
              menu: {
                title: 'Inspect',
              }
            }
          }
        ]
      },
      {
        path: 'admin',
        data: {
          menu: {
            title: 'Administration',
            icon: 'ion-wrench',
            selected: false,
            expanded: false,
            order: 100,
          }
        },
        children: [
          {
            path: 'locations',
            data: {
              menu: {
                title: 'Manage Locations',
              }
            }
          },
          {
            path: 'devices',
            data: {
              menu: {
                title: 'Manage Devices',
              }
            }
          },
          {
            path: 'users',
            data: {
              menu: {
                title: 'Manage Users',
              }
            }
          }
        ]
      },
      {
        path: 'help',
        data: {
          menu: {
            title: 'Help',
            icon: 'ion-help',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      }
    ]
  }
];
