import { GlobalState } from '../../../../global.state';
import { ContextService } from '../../../services/context.service';
import { PlannedLayoutService } from '../../../services/plannedlayout.service';
var BUTTONTEXT;
(function (BUTTONTEXT) {
    BUTTONTEXT["AREA"] = "Zuerst Leergutplatz ausw\u00E4hlen.";
    BUTTONTEXT["NONE"] = "SOLL-Belegung nicht vorhanden.";
    BUTTONTEXT["CHOOSE"] = "W\u00E4hle SOLL-Belegung";
    BUTTONTEXT["UNDEFINED"] = "";
})(BUTTONTEXT || (BUTTONTEXT = {}));
var plannedLayoutSelector = /** @class */ (function () {
    function plannedLayoutSelector(contextService, plannedlayoutService, _state) {
        this.contextService = contextService;
        this.plannedlayoutService = plannedlayoutService;
        this._state = _state;
        this.buttontext = BUTTONTEXT.AREA;
        this.selected = 'empty';
        this.list = [];
        this.editmode = false;
        this.disabled = false;
        this.useplannedlayoutcontext = true;
    }
    Object.defineProperty(plannedLayoutSelector.prototype, "area", {
        set: function (a) {
            if (a) {
                this._area = a;
                this.list = this.plannedlayoutService.getPlannedLayoutsOfArea(this._area.id);
            }
        },
        enumerable: true,
        configurable: true
    });
    plannedLayoutSelector.prototype.ngOnInit = function () {
        var _this = this;
        // update dropdown when planned layouts get added
        this.sub_plannedlayouts = this.plannedlayoutService.plannedlayouts.subscribe(function (layouts) {
            if ((!_this.contextService.area || !layouts) && !_this._area)
                return;
            if (!_this._area) {
                _this.list = _this.plannedlayoutService.getPlannedLayoutsOfArea(_this.contextService.area.id);
            }
            else {
                _this.list = _this.plannedlayoutService.getPlannedLayoutsOfArea(_this._area.id);
            }
            if (_this.list.length == 0) {
                _this.disabled = true;
                _this.buttontext = BUTTONTEXT.NONE;
            }
            else {
                _this.disabled = false;
            }
        });
    };
    plannedLayoutSelector.prototype.ngOnDestroy = function () {
        if (this.sub_area)
            this.sub_area.unsubscribe();
        if (this.sub_plannedlayout)
            this.sub_plannedlayout.unsubscribe();
        if (this.sub_plannedlayouts)
            this.sub_plannedlayouts.unsubscribe();
    };
    plannedLayoutSelector.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this._area && this.contextService.area) {
            this.list = this.plannedlayoutService.getPlannedLayoutsOfArea(this.contextService.area.id);
        }
        else if (this._area) {
            this.list = this.plannedlayoutService.getPlannedLayoutsOfArea(this._area.id);
        }
        if (this.list.length == 0) {
            this.buttontext = BUTTONTEXT.NONE;
            return;
        }
        else
            this.buttontext = BUTTONTEXT.CHOOSE;
        var ctx = this.contextService.context;
        if (ctx) {
            var pl = this.plannedlayoutService.getPlannedLayout(ctx.plannedlayoutId);
            if (!pl)
                console.warn('PlannedLayout: non-existing id from context');
            else
                this.select(pl);
        }
        if (this.useplannedlayoutcontext) {
            this.sub_plannedlayout = this.contextService.selectedPlannedLayout$.subscribe(function (pl) {
                if (!pl) {
                    _this.selected = undefined;
                    if (_this.list.length == 0) {
                        _this.buttontext = BUTTONTEXT.NONE;
                        return;
                    }
                    else {
                        _this.buttontext = BUTTONTEXT.CHOOSE;
                    }
                }
                else {
                    _this.selected = pl;
                    _this.buttontext = BUTTONTEXT.UNDEFINED;
                }
            });
        }
    };
    plannedLayoutSelector.prototype.select = function (pl) {
        if (pl) {
            this.selected = pl;
            this.buttontext = BUTTONTEXT.UNDEFINED;
            if (this._area) {
                this._area.plannedlayouts = [];
                this._area.plannedlayouts.push(pl);
            }
            if (this.useplannedlayoutcontext) {
                this.contextService.plannedlayout = pl;
            }
        }
    };
    return plannedLayoutSelector;
}());
export { plannedLayoutSelector };
