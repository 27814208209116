import { MatSnackBar } from '@angular/material/snack-bar';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export var InfoDurationTime = 3000;
export var InfoType;
(function (InfoType) {
    InfoType[InfoType["error"] = 1] = "error";
    InfoType[InfoType["info"] = 2] = "info";
    InfoType[InfoType["success"] = 3] = "success";
})(InfoType || (InfoType = {}));
var SnackBarService = /** @class */ (function () {
    function SnackBarService(snackBar) {
        this.snackBar = snackBar;
    }
    SnackBarService.prototype.showBar = function (id, msg, info, type) {
        var className = type === InfoType.info
            ? ['grey-snackbar']
            : type === InfoType.success
                ? ['green-snackbar']
                : type == InfoType.error
                    ? ['red-snackbar']
                    : ['orange-snackbar'];
        this.snackBar.open("[" + id + "] " + msg, 'OK', {
            duration: InfoDurationTime,
            panelClass: className,
        });
    };
    SnackBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackBarService_Factory() { return new SnackBarService(i0.ɵɵinject(i1.MatSnackBar)); }, token: SnackBarService, providedIn: "root" });
    return SnackBarService;
}());
export { SnackBarService };
