import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/Rx";

import { ApiService } from "../../api/services"
import { Box } from "../../api/models"

@Injectable()
export class BoxesService {

  private _boxes: BehaviorSubject<Array<Box>> = new BehaviorSubject(Array<Box>());
  public readonly boxes: Observable<Box[]> = this._boxes.asObservable();

  constructor(private api: ApiService) {

  }

  hasBoxes(): boolean {
    if (!this._boxes || this._boxes.isEmpty())
      return false;

    return true;
  }

  loadBoxes(): void {
    this.api.BoxServiceGetBoxs("").subscribe(
      bs => {
        this._boxes.next(bs);
      },
      error => {
        console.log("Error retrieving boxes");
      });
  }


  getBox(id: number): Box {
    for (let r of this._boxes.value) {
      if (r.id === id) {
        return r;
      }
    }
    return undefined;
  }


  getBoxByBookingcode(bookingcode: string): Box {
    for (let r of this._boxes.value) {
      if (r.booking_code === bookingcode) {
        return r;
      }
    }
    return undefined;
  }


}
