import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ApiService } from '../../api/services';
import { Area, Site, Place, PlannedLayout, Stocktake } from '../../api/models';

import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';

/**
 * ContextService, hold user selected contexts, e.g. location, building
 */
@Injectable()
export class ContextService {
  private baseContext;

  // Observable building and location sources
  private selectedActiveStepSource = new Subject<number>();
  private selectedAreaSource = new Subject<Area>();
  private selectedSiteSource: BehaviorSubject<Site> = new BehaviorSubject<Site>(undefined);
  private selectedLocationSource = new Subject<Place>();
  private selectedPlannedLayoutSource = new Subject<PlannedLayout>();
  private selectedStocktakeSource = new Subject<Stocktake>();

  // Observable string streams
  selectedActiveStep$ = this.selectedActiveStepSource.asObservable();
  selectedArea$ = this.selectedAreaSource.asObservable();
  selectedSite$ = this.selectedSiteSource.asObservable();
  selectedLocation$ = this.selectedLocationSource.asObservable();
  selectedPlannedLayout$ = this.selectedPlannedLayoutSource.asObservable();
  selectedStocktake$ = this.selectedStocktakeSource.asObservable();

  context: any;

  private _activeStep: number;
  private _site: Site;
  private _area: Area;
  private _location: Place;
  private _plannedlayout: PlannedLayout;
  private _stocktake: Stocktake;

  constructor(private _state: GlobalState, private api: ApiService) {
    this._location = {
      id: environment.locationId,
      activated: true,
      sites: undefined,
      city: undefined,
      comment: undefined,
      country: undefined,
      countrycode: undefined,
      latitude: undefined,
      longitude: undefined,
      map_marker: undefined,
      name: undefined,
      postalcode: undefined,
      street: undefined,
    };

    this.context = { activeStep: 3, siteId: 1, locationId: 1, plannedlayoutId: 1, areaId: 17, stocktakeId: 227 };
    /**api.ContextServiceGetGUIContext(0).subscribe(res => {
      this.context = res;
      this.activeStep = res.activeStep;
    });*/
  }

  /**
   Active step of user wizard
   */
  get activeStep(): number {
    return this._activeStep;
  }

  /**
   Current selected area (e.g. "Leergutplatz H52")
   */
  get area(): Area {
    return this._area;
  }

  /**
 Current selected site
 */
  get site(): Site {
    return this._site;
  }

  /**
   Current selected location (e.g. "BMW Werk Leipzig")
   */
  get location(): Place {
    return this._location;
  }

  /**
   Current selected planned layout (e.g. "SOLL Belegung Januar 2018")
   */
  get plannedlayout(): PlannedLayout {
    return this._plannedlayout;
  }

  /**
   Current selected stocktake user is looking at or creating
   */
  get stocktake(): Stocktake {
    return this._stocktake;
  }

  set activeStep(n: number) {
    /**this.api.ContextServiceUpdateActiveStep({ id: 0, newstep: { data: n } }).toPromise().then(
      res => {
      },
      err => {
        console.error("error saving Activestep")
      }
    );*/

    this._activeStep = n;
    this._state.notifyDataChanged('context.ActiveStepSelected', n);
    this.selectedActiveStepSource.next(n);
  }

  set area(a: Area) {
    let id = undefined;
    if (a) id = a.id;

    /**this.api.ContextServiceUpdateArea({ id: 0, newId: { data: id } }).toPromise().then(
      res => {
      },
      err => {
        console.error("error saving Area")
      }
    );*/

    this._area = a;
    this._state.notifyDataChanged('context.AreaSelected', a);
    this.selectedAreaSource.next(a);
  }

  set site(s: Site) {
    let id = undefined;
    if (s) id = s.id;

    this.context.siteId = id;

    //@TODO
    /**this.api.ContextServiceUpdateSite({ id: 0, newId: { data: id } }).toPromise().then(
      res => {
      },
      err => {
        console.error("error saving Site")
      }
    );*/

    this._site = s;
    this._state.notifyDataChanged('context.SiteSelected', s);
    this.selectedSiteSource.next(s);
  }

  set location(loc: Place) {
    let id = undefined;
    if (loc) id = loc.id;

    /**this.api.ContextServiceUpdateLocation({ id: 0, newId: { data: id } }).toPromise().then(
      res => {
      },
      err => {
        console.error("error saving location");
      }
    );*/

    this._location = loc;
    this._state.notifyDataChanged('context.LocationSelected', loc);
    this.selectedLocationSource.next(loc);
  }

  set plannedlayout(p: PlannedLayout) {
    let id = undefined;
    if (p) id = p.id;

    /**this.api.ContextServiceUpdatePlannedLayout({ id: 0, newId: { data: id } }).toPromise().then(
      res => {
      },
      err => {
        console.error("error saving PlannedLayout");
      }
    );*/

    this._plannedlayout = p;
    this._state.notifyDataChanged('context.PlannedLayoutSelected', p);
    this.selectedPlannedLayoutSource.next(p);
  }

  set stocktake(s: Stocktake) {
    let id = undefined;
    if (s) id = s.id;

    /**this.api.ContextServiceUpdateStocktake({ id: 0, newId: { data: id } }).toPromise().then(
      res => {
      },
      err => {
        console.error("error saving stocktake")
      }
    );*/

    this._stocktake = s;
    this._state.notifyDataChanged('context.StocktakeSelected', s);
    this.selectedStocktakeSource.next(s);
  }
}
