import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserData } from '../../authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private http: HttpClient) {}

  getUsers(): Observable<UserData[]> {
    return this.http.get<UserData[]>(`api/users`);
  }

  addUser(user: UserData): Observable<UserData> {
    return this.http.post<UserData>(`api/users`, user);
  }

  updateUser(user: UserData): Observable<UserData> {
    return this.http.put<UserData>(`api/users`, user);
  }

  deleteUser(id: number): Observable<UserData> {
    return this.http.delete<UserData>(`api/users/${id}`);
  }
}
