import { Component, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment/moment';

import { ContextService } from '../../../services/context.service';
import { StocktakeService } from '../../../services/stocktake.service';

import { Stocktake } from '../../../../api/models/stocktake';

import { GlobalState } from '../../../../global.state';

enum BUTTONTEXT {
  AREA = 'Zuerst Leergutplatz auswählen.',
  NONE = 'Kein Bestand vorhanden.',
  CHOOSE = 'Wähle Bestandsaufnahme',
  UNDEFINED = '',
}

@Component({
  selector: 'select-stocktake',
  templateUrl: './selectStocktake.component.html',
  styleUrls: ['./selectStocktake.component.scss'],
})
export class stockSelector {

  public buttontext: string = BUTTONTEXT.NONE;
  public selected: Stocktake;
  public selectedStocktake: any = '';
  private selected_index: number = 0;
  public stocktakes: Stocktake[] = [];
  private sub: any;

  constructor(
    private cdRef: ChangeDetectorRef,
    private _state: GlobalState,
    private contextService: ContextService,
    private stocktakeService: StocktakeService,
  ) {
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    // listen to selected area, get stocktakes for them
    this.sub = this.contextService.selectedArea$.subscribe(
      area => {
        if (!area) {
          this.stocktakes = undefined;
          this.buttontext = BUTTONTEXT.AREA;
          return;
        }

        this.stocktakeService.getStocktakesForArea(area.id).then(
          stocktakes => {
            this.stocktakes = stocktakes.reverse();
            if (this.stocktakes.length > 0) {
               
              let selectedStocktake = undefined
              if(this.contextService.context.stocktakeId) {  
                selectedStocktake = stocktakes.find(stocktake => stocktake.id == this.contextService.context.stocktakeId)
              }
              
              if(selectedStocktake) {
                this.contextService.stocktake = selectedStocktake
                this.selected = selectedStocktake
                this.buttontext = BUTTONTEXT.UNDEFINED
                this.selectedStocktake = this.getDate(selectedStocktake)
              } else {
                this.contextService.stocktake = this.stocktakes[0]
                this.selected = this.stocktakes[0]
                this.buttontext = BUTTONTEXT.UNDEFINED
                this.selectedStocktake = this.getDate(this.stocktakes[0])
              }
            } else {
              this.buttontext = BUTTONTEXT.NONE;
            }
          },
        );
      },
    );
  }

  select(s: Stocktake, i: number) {
    this.selected = s;
    this.selected_index = i;
    this.selectedStocktake = this.getDate(this.selected)
    this.buttontext = BUTTONTEXT.UNDEFINED;
    this.contextService.stocktake = this.selected;
  }

  prevStockClicked(): void {
    if (this.hasPrevStock()) {
      this.selected = this.stocktakes[--this.selected_index];
      this.select(this.selected, this.selected_index);
    }
  }

  nextStockClicked(): void {
    if (this.hasNextStock()) {
      this.selected = this.stocktakes[++this.selected_index];
      this.select(this.selected, this.selected_index);
    }
  }

  hasPrevStock(): boolean {
    return (this.selected_index > 0);
  }

  hasNextStock(): boolean {
    if (this.stocktakes) {
      return (this.selected_index < (this.stocktakes.length - 1))
    } else {
      return false
    }

  }

  getDate(s: Stocktake): string {
    if (!s) {
      return '';
    }
    return moment(s.startdate).format('llll');
  }

}
