import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PlannedLayout } from '../../../../api/models';

@Component({
  selector: 'addplannedlayout-modal',
  templateUrl: './addplannedlayout.component.html',
  styleUrls: ['./addplannedlayout.component.scss'],
})
export class doksAddPlannedLayoutModal {
  modalHeader: string;
  plannedlayout: PlannedLayout;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  closeModal(cancel: boolean) {
    if (!cancel) this.activeModal.close(false);

    this.activeModal.close(this.plannedlayout);
  }
}
