import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { yesNoModal } from '../../doks/components/modals';
import { TableModule } from '../../doks/components/table/table.module';
import { doksModule } from '../../doks/doks.module';
import { UserManagementDialogModule } from './modals/user-management-dialog/user-management-dialog.module';
import { UserManagementComponent } from './page/user-management.component';
import { UserManagementRoutingModule } from './user-management-routing.module';

@NgModule({
  declarations: [UserManagementComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatMenuModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    UserManagementRoutingModule,
    TableModule,
    UserManagementDialogModule,
    doksModule,
  ],
  exports: [UserManagementComponent],
  entryComponents: [yesNoModal],
})
export class UserManagementModule {}
