/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./currentLocation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./currentLocation.component";
import * as i3 from "../../../../../global.state";
import * as i4 from "../../../../services/context.service";
var styles_currentLocation = [i0.styles];
var RenderType_currentLocation = i1.ɵcrt({ encapsulation: 0, styles: styles_currentLocation, data: {} });
export { RenderType_currentLocation as RenderType_currentLocation };
export function View_currentLocation_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.location_title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.location_subtitle; _ck(_v, 3, 0, currVal_1); }); }
export function View_currentLocation_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "current-location", [], null, null, null, View_currentLocation_0, RenderType_currentLocation)), i1.ɵdid(1, 49152, null, 0, i2.currentLocation, [i3.GlobalState, i4.ContextService], null, null)], null, null); }
var currentLocationNgFactory = i1.ɵccf("current-location", i2.currentLocation, View_currentLocation_Host_0, {}, {}, []);
export { currentLocationNgFactory as currentLocationNgFactory };
