import { Injectable } from '@angular/core';
import ReconnectingWebsocket from 'reconnecting-websocket';

import { Observer } from 'rxjs/Observer';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/Rx';

import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';
import { EnvService } from '../../env.service';

/**
 * Enum of websocket messages
 */
export enum wsMessageType {
  WelcomeBackend = 0,
  WelcomeCC = 1,
  WhoIsThere = 2,

  GetFlightInfos = 10,
  StartProcessing = 11,
  StartReprocess = 12,
  StartExport = 13,
  GetBlockdeviceState = 14,

  CCServiceCleanWorkspace = 15,
  CCServiceGetCloudClientState = 16,
  CCServiceRestartSegmenting = 17,

  FlightInfos = 20,
  ProcessingProgress = 21,
  ProcessingResult = 22,
  ExportResult = 23,
  BlockdeviceState = 24,

  CCServiceCloudClientStateResult = 26,
  CCServiceAbort = 100,
}

/**
 * Interface of general websocket message structure
 */
export interface wsMessage {
  t: wsMessageType;
  d: any;
}

@Injectable()
export class WebsocketService {
  private static ws: ReconnectingWebsocket;

  private static subject: Subject<MessageEvent>;
  public messages: Subject<wsMessage>;

  constructor(private _state: GlobalState, private env: EnvService) {
    this.connect(this.env.wsURL);
  }

  private connect(url): void {
    console.log('connecting to backend by websocket...');
    if (!WebsocketService.subject) {
      console.log('creating new websocket...');
      WebsocketService.subject = this.create(url);
    }

    this.remap();
  }

  private remap() {
    // remap incoming messages to observable wsMessage
    this.messages = <Subject<wsMessage>>WebsocketService.subject.map((response: MessageEvent): wsMessage => {
      try {
        let data = JSON.parse(response.data);
        return {
          t: data.t,
          d: data.d,
        };
      } catch (err) {
        console.error('error parsing incoming websocket msg: ', response);
      }
    });
  }

  private create(url): Subject<MessageEvent> {
    WebsocketService.ws = new ReconnectingWebsocket(url);

    WebsocketService.ws.addEventListener('open', (e) => {
      console.log('Websocket successfully connected: ' + url);
      this._state.notifyDataChanged('websocket.connected', true);
    });
    WebsocketService.ws.addEventListener('close', (e) => {
      this._state.notifyDataChanged('websocket.connected', false);
    });
    WebsocketService.ws.addEventListener('error', (e) => {});

    let observable = Observable.create((obs: Observer<MessageEvent>) => {
      WebsocketService.ws.onmessage = obs.next.bind(obs);
      // HINT: to keep observer's subscription, just dont forward errors and closes
      //WebsocketService.ws.onerror = obs.error.bind(obs);
      //WebsocketService.ws.onclose = obs.complete.bind(obs);
      return WebsocketService.ws.close.bind(WebsocketService.ws);
    });

    let observer = {
      next: (data: Object) => {
        console.log('WebsocketService next readyState: ' + WebsocketService.ws.readyState);
        if (WebsocketService.ws.readyState === WebSocket.OPEN) {
          WebsocketService.ws.send(JSON.stringify(data));
        }
      },
    };

    return Subject.create(observer, observable);
  }

  public send(data) {
    WebsocketService.ws.send(data);
  }
}
