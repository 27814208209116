import { Component, Input } from '@angular/core';

import { GlobalState } from '../../../../global.state';
import { ContextService } from '../../../services/context.service';
import { PlannedLayoutService } from '../../../services/plannedlayout.service';

import { Area, PlannedLayout } from '../../../../api/models';

enum BUTTONTEXT {
  AREA = 'Zuerst Leergutplatz auswählen.',
  NONE = 'SOLL-Belegung nicht vorhanden.',
  CHOOSE = 'Wähle SOLL-Belegung',
  UNDEFINED = '',
}

@Component({
  selector: 'plannedlayoutselector',
  templateUrl: './plannedlayoutselector.component.html',
  styleUrls: ['./plannedlayoutselector.component.scss'],
})
export class plannedLayoutSelector {
  private buttontext: string = BUTTONTEXT.AREA;
  public selected: any = 'empty';
  public list: PlannedLayout[] = [];
  private _area: Area;
  private sub_area: any;
  private sub_plannedlayouts: any;
  private sub_plannedlayout: any;

  @Input()
  editmode: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  useplannedlayoutcontext: boolean = true;

  @Input()
  set area(a: Area) {
    if (a) {
      this._area = a;
      this.list = this.plannedlayoutService.getPlannedLayoutsOfArea(this._area.id);
    }
  }

  constructor(
    private contextService: ContextService,
    private plannedlayoutService: PlannedLayoutService,
    private _state: GlobalState,
  ) {}

  ngOnInit() {
    // update dropdown when planned layouts get added
    this.sub_plannedlayouts = this.plannedlayoutService.plannedlayouts.subscribe((layouts) => {
      if ((!this.contextService.area || !layouts) && !this._area) return;
      if (!this._area) {
        this.list = this.plannedlayoutService.getPlannedLayoutsOfArea(this.contextService.area.id);
      } else {
        this.list = this.plannedlayoutService.getPlannedLayoutsOfArea(this._area.id);
      }
      if (this.list.length == 0) {
        this.disabled = true;
        this.buttontext = BUTTONTEXT.NONE;
      } else {
        this.disabled = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.sub_area) this.sub_area.unsubscribe();
    if (this.sub_plannedlayout) this.sub_plannedlayout.unsubscribe();
    if (this.sub_plannedlayouts) this.sub_plannedlayouts.unsubscribe();
  }

  ngAfterViewInit() {
    if (!this._area && this.contextService.area) {
      this.list = this.plannedlayoutService.getPlannedLayoutsOfArea(this.contextService.area.id);
    } else if (this._area) {
      this.list = this.plannedlayoutService.getPlannedLayoutsOfArea(this._area.id);
    }

    if (this.list.length == 0) {
      this.buttontext = BUTTONTEXT.NONE;
      return;
    } else this.buttontext = BUTTONTEXT.CHOOSE;

    var ctx = this.contextService.context;
    if (ctx) {
      let pl = this.plannedlayoutService.getPlannedLayout(ctx.plannedlayoutId);
      if (!pl) console.warn('PlannedLayout: non-existing id from context');
      else this.select(pl);
    }

    if (this.useplannedlayoutcontext) {
      this.sub_plannedlayout = this.contextService.selectedPlannedLayout$.subscribe((pl) => {
        if (!pl) {
          this.selected = undefined;

          if (this.list.length == 0) {
            this.buttontext = BUTTONTEXT.NONE;
            return;
          } else {
            this.buttontext = BUTTONTEXT.CHOOSE;
          }
        } else {
          this.selected = pl;
          this.buttontext = BUTTONTEXT.UNDEFINED;
        }
      });
    }
  }

  select(pl: PlannedLayout) {
    if (pl) {
      this.selected = pl;
      this.buttontext = BUTTONTEXT.UNDEFINED;
      if (this._area) {
        this._area.plannedlayouts = [];
        this._area.plannedlayouts.push(pl);
      }
      if (this.useplannedlayoutcontext) {
        this.contextService.plannedlayout = pl;
      }
    }
  }
}
