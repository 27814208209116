import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { AuthService } from '../../../authentication/auth.service';
import { MustMatch } from '../../../doks/helpers/mustMatchValidator';
import { SnackBarService, InfoType } from '../../../doks/services/snack-bar.service';
import { UserService } from '../../../doks/services/user.service';

@Component({
  selector: 'gate-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  private subs: SubSink = new SubSink();
  public resetPasswordForm: FormGroup;
  public token: string;
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: new FormControl(null, [Validators.required]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      },
    );
    this.token = this.authService.getTokenData() ? this.authService.getTokenData().token : null;
  }
  public changePassword(formDirective: FormGroupDirective) {
    const passwordData = { token: this.token, password: this.resetPasswordForm.value.password };
    this.subs.sink = this.userService.changePassword(passwordData).subscribe(
      () => {
        this.snackBarService.showBar(156, 'Passwort wurde aktualisiert', '', InfoType.success);
        formDirective.resetForm();
        this.resetPasswordForm.reset();
      },
      (err) => {
        this.snackBarService.showBar(157, 'Etwas ist schief gelaufen', err, InfoType.error);
      },
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
