import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { RouteConfigLoadEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { SubSink } from 'subsink';
import { AuthService } from './authentication/auth.service';
import { UserService } from './doks/services/user.service';

import { GlobalState } from './global.state';
import { BaImageLoaderService, BaThemePreloader, BaThemeSpinner } from './theme/services';
import { BaThemeConfig } from './theme/theme.config';
import { layoutPaths } from './theme/theme.constants';

import { ContextService } from './doks/services/context.service';
import { LocationService } from './doks/services/location.service';

import { environment } from '../environments/environment';

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  styleUrls: ['./app.component.scss'],
  template: `
    <main [class.menu-collapsed]="isMenuCollapsed" baThemeRun>
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
    </main>
  `,
})
export class App implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  public isMenuCollapsed: boolean = false;
  public isRouteLoad = true;
  constructor(
    private _state: GlobalState,
    private _imageLoader: BaImageLoaderService,
    private _spinner: BaThemeSpinner,
    private viewContainerRef: ViewContainerRef,
    private themeConfig: BaThemeConfig,
    private contextService: ContextService,
    private locationService: LocationService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.themeConfig.config();

    moment.locale(environment.language);

    this.locationService.loadInitialData();
    this.locationService.getLocation(environment.locationId).subscribe((location) => {
      this.contextService.location = location;
      BaThemePreloader.load().then((values) => {
        this._spinner.hide();
      });
    });

    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

    const currentUser = window.localStorage.getItem('currentUser');
    if (currentUser) {
      try {
        this.userService.setCurrentUser(JSON.parse(currentUser));
      } catch (err) {
        this.authService.resetAuthData();
      }
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
