import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { RoleEnum } from '../../doks/interfaces/roles';
import { InfoType, SnackBarService } from '../../doks/services/snack-bar.service';
import { UserService } from '../../doks/services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../doks/services/user.service";
import * as i3 from "../../doks/services/snack-bar.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, userService, snackBarService) {
        this.router = router;
        this.userService = userService;
        this.snackBarService = snackBarService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.userService._currentuser.pipe(map(function (authenticated) {
            if (authenticated && _this.userService.getCurrentUser()) {
                if (_this.userService
                    .getCurrentUser()
                    .roles.some(function (el) { return [RoleEnum.Admin, RoleEnum.User, RoleEnum.Editor].includes(el); })) {
                    return true;
                }
                return (_this.router.navigate(['login'], { queryParams: { returnUrl: state.url } }),
                    _this.snackBarService.showBar(1, 'Konto nicht vom Administrator verifiziert', '', InfoType.error));
            }
            return (_this.router.navigate(['login'], { queryParams: { returnUrl: state.url } }),
                _this.snackBarService.showBar(1, 'Konto nicht vom Administrator verifiziert', '', InfoType.error));
        }));
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.SnackBarService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
