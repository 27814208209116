import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router';
import { ContextService } from 'app/doks/services';
import { Stock } from 'app/pages/operator';
import { WizardService } from 'app/pages/operator/wizard';
import { Stocktake } from '../../../../api/models/stocktake';
import { StocktakeService } from "../../../services/stocktake.service";

@Component({
  selector: 'stocklist-component',
  templateUrl: './stocks.component.html',
})
export class stocksList {

  public stocktakes: Array<Stocktake> = new Array<Stocktake>();
  private sub: any;
  private _editable: boolean;

  public displayedColumns: string[] = ['id', "flightdate_start", "flightdate_stop", "status", "options"]
  public dataSource = new MatTableDataSource<Stocktake>();

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  @Input()
  set editable(b: boolean) {
    this._editable = b;
  }
  get editable(): boolean { return this._editable; }

  constructor(private stocktakeService: StocktakeService,
    private contextService: ContextService,
    private wizardService: WizardService,
    private router: Router,
    private route: ActivatedRoute) {
    this.getStocktakes()
  }

  public async getStocktakes() {
    this.stocktakes = await (await this.stocktakeService.getStocktakes()).reverse()
    this.dataSource = new MatTableDataSource<Stocktake>(this.stocktakes)
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  public async gotostock(stocktake) {
    this.wizardService.stocktake =  await this.stocktakeService.getStocktake(stocktake.id)
    this.router.navigate(['/pages/stock/add'], { relativeTo: this.route });
  }

  public gotooverview(stocktake: Stocktake) {
    this.contextService.area = stocktake.stocktaketoareas[0].area
    let context = this.contextService.context
    context.areaId = this.contextService.area.id
    context.stocktakeId = stocktake.id
    this.contextService.context = context
    this.router.navigate(['/pages/stock/overview'], { relativeTo: this.route });
  }
  ngOnDestroy() {
    //this.sub.unsubscribe();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
