<div class="reloadbox">
  <div class="link" (click)="reloadWindow()">
    <div class="reloadbox-inner">
    	<div class="icon"><i class="fa fa-refresh"></i></div>
    	<div class="textlevel">
    	  Neu laden
    	</div>
    </div>
  </div>
</div>
