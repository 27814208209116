import { EventEmitter, OnInit, } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import * as moment from 'moment/moment';
import { BackendService } from '../../../services/backend.service';
import { ContextService } from '../../../services/context.service';
import { StocktakeService } from '../../../services/stocktake.service';
import { BoxesService } from '../../../services/boxes.service';
import { GlobalState } from '../../../../global.state';
import { EnvService } from '../../../../env.service';
var BUTTONTEXT;
(function (BUTTONTEXT) {
    BUTTONTEXT["AREA"] = "Zuerst Leergutplatz ausw\u00E4hlen.";
    BUTTONTEXT["NONE"] = "Kein Bestand vorhanden.";
    BUTTONTEXT["CHOOSE"] = "W\u00E4hle Bestandsaufnahme";
})(BUTTONTEXT || (BUTTONTEXT = {}));
var stockTable = /** @class */ (function () {
    function stockTable(_state, backendService, contextService, stocktakeService, boxesService, env) {
        this._state = _state;
        this.backendService = backendService;
        this.contextService = contextService;
        this.stocktakeService = stocktakeService;
        this.boxesService = boxesService;
        this.env = env;
        this.buttontext = BUTTONTEXT.NONE;
        this._stock = new Array();
        this._show = false;
        this.displayedColumns = ['total_count', 'multiplied_count', 'booking_code', 'name'];
        this.dataSource = new MatTableDataSource();
        this.selectedEntry = new EventEmitter();
    }
    Object.defineProperty(stockTable.prototype, "stock", {
        get: function () {
            return this._stock;
        },
        set: function (s) {
            this._stock = s;
            var t = new Array();
            if (s) {
                for (var _i = 0, s_1 = s; _i < s_1.length; _i++) {
                    var entry = s_1[_i];
                    t.push({
                        multiplied_count: entry.total_count * entry.box.multiplicator,
                        total_count: entry.total_count,
                        booking_code: entry.box.booking_code,
                        name: entry.box.name,
                    });
                }
            }
            this.dataSource = new MatTableDataSource(t);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(stockTable.prototype, "show", {
        set: function (b) {
            this._show = b;
        },
        enumerable: true,
        configurable: true
    });
    stockTable.prototype.ngOnInit = function () {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    };
    stockTable.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    stockTable.prototype.ngAfterViewInit = function () {
        var _this = this;
        // listen to selected area, get stocktakes for them
        this.sub = this.contextService.selectedStocktake$.subscribe(function (stocktake) {
            _this.stocktake = stocktake;
        });
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    };
    stockTable.prototype.applyFilter = function (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    stockTable.prototype.selectEntry = function (entry) {
        if (this._selected_entry == entry) {
            this._selected_entry = undefined;
            this.selectedEntry.emit(undefined);
        }
        else {
            this._selected_entry = entry;
            this.selectedEntry.emit(entry.booking_code);
        }
    };
    stockTable.prototype.getHostname = function () {
        return this.env.hostname;
    };
    stockTable.prototype.getDate = function (s) {
        if (!s) {
            return '';
        }
        return moment(s.startdate).format('llll');
    };
    stockTable.prototype.exportToFile = function () {
        var _this = this;
        var rows = [
            [
                'LNr',
                'Typ',
                /*'Lagerplatz', 'SpKz',*/ 'Material',
                'Materialkurztext',
                'Stapelhoehe',
                'Zaehlmenge',
                'Kartons',
                'Zuständige Person',
                'Pilot'
            ],
        ];
        this._stock.forEach(function (element) {
            var row = [
                element.box.id,
                element.box.type,
                // ', // Lagerplatz
                // ', // SpKz
                element.box.booking_code,
                element.box.name // Materialkurztext
                    .replace(/,/g, ' -')
                    .replace(/;/g, '. ')
                    .replace(/ö/g, 'oe')
                    .replace(/ä/g, 'ae')
                    .replace(/ü/g, 'ue')
                    .replace(/ß/g, 'ss'),
                element.box.max_stack,
                element.total_count,
                element.total_count * element.box.multiplicator,
                _this.stocktake.user,
                _this.stocktake.pilot
                // ' // BME
            ];
            // console.log(row.toString())
            rows.push(row);
        });
        var csvContent = 'data:text/csv;charset=utf-8,' + rows.map(function (e) { return e.join(','); }).join('\n');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', "stocktake_" + this.stocktake.id + ".csv");
        document.body.appendChild(link);
        link.click();
    };
    return stockTable;
}());
export { stockTable };
