import { ModuleWithProviders } from '@angular/core';
import { BaThemeConfig } from './theme.config';
import { BaThemeConfigProvider } from './theme.configProvider';
import { BaThemeRun } from './directives';
import { BaAppPicturePipe, BaKameleonPicturePipe, BaProfilePicturePipe } from './pipes';
import { BaImageLoaderService, BaMenuService, BaThemePreloader, BaThemeSpinner } from './services';
import { EmailValidator, EqualPasswordsValidator } from './validators';
var NGA_DIRECTIVES = [
    BaThemeRun,
];
var NGA_PIPES = [
    BaAppPicturePipe,
    BaKameleonPicturePipe,
    BaProfilePicturePipe
];
var NGA_SERVICES = [
    BaImageLoaderService,
    BaThemePreloader,
    BaThemeSpinner,
    BaMenuService
];
var NGA_VALIDATORS = [
    EmailValidator,
    EqualPasswordsValidator
];
var NgaModule = /** @class */ (function () {
    function NgaModule() {
    }
    NgaModule.forRoot = function () {
        return {
            ngModule: NgaModule,
            providers: [
                BaThemeConfigProvider,
                BaThemeConfig
            ].concat(NGA_VALIDATORS, NGA_SERVICES),
        };
    };
    return NgaModule;
}());
export { NgaModule };
